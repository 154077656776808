import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import { openModal } from 'helpers/Modal';
import Modal_upload from 'pages/Admin/Modal_upload';
import Modal_article from 'pages/Admin/Modal_Article';
import Modal_menu from 'pages/Admin/Modal_menu';
import HeaderAdmin from 'pages/Admin/HeaderAdmin';
import MenuAdmin from 'pages/Admin/MenuAdmin';
import { resetFormValues } from "helpers/resetForm";
import menuAdmin from 'helpers/menuAdmin';
import * as XLSX from 'xlsx'; // Importe a biblioteca xlsx-populate
import ExcelJS from 'exceljs';

import { saveAs } from 'file-saver';
const Recebimento = () => {
  const api = useAPI();
  const { id } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [events,setEvents] = useState([])
  const [articles,setArticles] = useState([])
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [formInfo, setFormInfo] = useState([]);
  const [formData, setFormData] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [eventInfo, setEventInfo] = useState({});
  const [data, setData] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null); // Estado para armazenar o item selecionado
  const [eventosPermitindoSubmissao,seteventosPermitindoSubmissao] =useState([]);
  const [menu,setMenu]=useState([]);
  const [tokenConferencia,setTokenConferencia]=useState([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const flattenObject = (obj, parentKey = '') => {
    let result = {};
  
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
  
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          const flattened = flattenObject(obj[key], newKey);
          result = { ...result, ...flattened };
        } else if (Array.isArray(obj[key])) {
          obj[key].forEach((item, index) => {
            if (typeof item === 'object') {
              const itemKeys = Object.keys(item);
              itemKeys.forEach(itemKey => {
                const newSubKey = `${newKey}.${index}.${itemKey}`;
                result[newSubKey] = item[itemKey];
              });
            }
          });
        } else {
          result[newKey] = obj[key];
        }
      }
    }
  
    return result;
  };
  
  const handleDownload = async (e, data, filename) => {
    try {
      e.preventDefault();
  
      const flattenedData = data.map(item => flattenObject(item));
      console.log(flattenedData);
  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
  
     // Obtenha todas as chaves (colunas)
    const headers = Array.from(new Set(flattenedData.flatMap(item => Object.keys(item))))
    .filter(header => header !== "content.introdução" && header !== 'content.metodologia' && header !== 'content.resultado' && header !== 'content.conclusao');;
      console.log(headers)
    // Adicionar cabeçalhos
    worksheet.addRow(headers);
  
      // Adicionar dados
      flattenedData.forEach(item => {
        const row = headers.map(header => item[header]);
        worksheet.addRow(row);
      });
  
      // Salvar o arquivo Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const excelBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      saveAs(excelBlob, `${filename}.xlsx`);
    } catch (error) {
      console.error('Error generating and downloading Excel:', error);
    }
  };
  
  
  
  useEffect(() => {
    console.log("O useEffect está sendo executado");
  
    const fetchData = async () => {
      try {
        console.log("estou sendo executado");
        setMenu(menuAdmin(0, id));
        const [eventos, articlesJson] = await Promise.all([
          api.getAllEvents(),
          api.getListArticle(id),
        ]);
  
        const eventosAceitandoResumos = eventos.filter(
          (item) => item.settings?.controllers?.permitirEnvio
        );
  
        
        seteventosPermitindoSubmissao(eventosAceitandoResumos);
        // Adicione verificações antes de usar os dados
      if (Array.isArray(articlesJson)) {
        setArticles(articlesJson);
        //const dataWithAuthorsInfo = await incluirInfoAutoresEevento(articlesJson);
        setData(articlesJson);
      } else {
        // Caso não seja um array, defina um array vazio
        setData([]);
        // Lide com a situação em que articlesJson não é um array
        console.error('Erro ao obter dados de artigos:', articlesJson);
      }
      } catch (error) {
        // Lidar com erros, se necessário
      }
    };
  
    fetchData();
  }, [refresh]); // Array de dependências vazio para executar o efeito apenas uma vez
  
  
 
//Inclui informação dos autores e do evento em cada item 
const incluirInfoAutoresEevento = async (bd) => {
    try {
      
      const listaDeUsuarios = await api.getListPeople();
      
      const newData = bd.map((item) => {
        const autoresComInfo = item.authors.map((autor) => {
          const usuario = listaDeUsuarios.find((user) => user.cpf === autor.cpf);
          if (usuario) {
            return { ...autor, name: usuario.name, email: usuario.email };
                }
                return autor;
        });
  
            return { ...item, authors: autoresComInfo };
      });
        return newData;
    } catch (error) {
        console.error("Erro ao incluir informações dos autores:", error);
        return bd;
    }
};
//Altera as variáveis formInfo e eventInfo
const getEventInfo = async (id) => {
    try {
        //pega os dados do evento no BD
        const json = await api.getEventById(id);
        //pega o formulário padrão
        const arrayForm = json.settings.formularios.formulario_artigo;
        ;
        setFormInfo(arrayForm); // Espera até que setFormInfo seja concluído
        setEventInfo(json);
        setTokenConferencia(json.tokenConferencia)
        return json; // Retorna os dados processados
    } catch (error) {
        setError({error});
        throw error; // Lança o erro para ser tratado no bloco then ou catch
    }
}
//Aciona o getEventInfo e se der certo, 
const handleBoxClick = (event, id, idEvent) => {
    getEventInfo(idEvent)
        .then(async(json) => {
            //data aramazena todos os artigos
                //retorna o artigo selecionado com base no id
                
                const selected = await api.getArticleByID(id);
                //armazena o artigo selecionado
                setSelectedArticle(selected);
                //abre o modal
                openModal(event, 'article');
                //popula a variavel formData para receber os dados do artigo selecionado
                setFormData(selected);
        })
        .catch(error => {
            // Lida com erros na obtenção das informações do evento
            console.error(error);
        });
};    
    
    


const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
};
// Filtra a lista de resumos com base no valor do input de busca
let filteredData =[]
if(Array.isArray(data) && data.length > 0){
filteredData = data.filter((item) => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const lowerCaseTitulo = item?.content?.titulo?.toLowerCase() || '';
    const lowerCaseEdital = item?.content?.edital?.toLowerCase() || '';
    const lowerCaseInstituicao = item?.content?.instituicao?.toLowerCase() || '';

    const lowerCaseId = item?._id?.toLowerCase() || '';

    // Função para verificar se o CPF ou email de algum autor inclui o valor de busca
    const authorContainsSearchValue = item.authors?.some((author) => {
    const lowerCaseCpf = author?.cpf?.toLowerCase() || '';
    const lowerCaseEmail = author?.email?.toLowerCase() || '';
    const lowerCaseNome = author?.name?.toLowerCase() || '';

    return lowerCaseCpf.includes(lowerCaseSearchValue)|| lowerCaseNome.includes(lowerCaseSearchValue) || lowerCaseEmail.includes(lowerCaseSearchValue);
    });

    return (
    lowerCaseTitulo.includes(lowerCaseSearchValue) ||
    lowerCaseInstituicao.includes(lowerCaseSearchValue) ||
    lowerCaseEdital.includes(lowerCaseSearchValue) ||

    lowerCaseId.includes(lowerCaseSearchValue) ||
    authorContainsSearchValue
    );
});
}
      
const statusMap = {
"Pendente": { className: "icon-ball-red", text: "Pendente" },
"Aguardando apresentação": { className: "icon-ball-white", text: "Resumo enviado" },
"Aguardando avaliação": { className: "icon-ball-orange", text: "Aguardando avaliação" },
"em Avaliação": { className: "icon-ball-yellow", text: "Em Avaliação" },
"Avaliado": { className: "icon-ball-green", text: "Avaliado" }
};
 console.log(data)

 const novoArray = data
 .filter(obj => {
   return (
     obj.content &&
     obj.content.titulo &&
     obj.content.edital &&
     obj.content.grandeArea &&
     obj.content.subArea &&
     obj.content.introdução &&
     obj.content.metodologia &&
     obj.content.resultado &&
     obj.content.conclusao &&
     obj.authors &&
     obj.authors.length > 0 &&
     obj.authors.every(
       author => author.name  && author.type
     ) 
     &&(!obj.content.linkConferencia)
   );
 })
 .map(obj => ({
   id: obj._id,
   titulo: obj.content.titulo,
   authors: obj.authors?.map(author => ({
     name: author.name,
     email: author.email,
     type: author.type
   })),
resumo: 
`${obj.content.edital} - ${obj.content.grandeArea} - ${obj.content.subArea}
${obj.content.palavras_chaves?obj.content.palavras_chaves:""}
${obj.content.colaboradores?obj.content.colaboradores:""}
${obj.content.introdução}
${obj.content.metodologia}
${obj.content.resultado}
${obj.content.conclusao}
`,
dataOriginal: obj
 }));


//Incluir resumo no conferencias 
const sendToConference = async (e, novoArray) => {
  e.preventDefault();
  if (novoArray.length > 0) {
    try {
      setUploading(true); // Indica que o envio está em andamento

      for (let i = 0; i < novoArray.length; i++) {
        const item = novoArray[i];
        const json = await api.getEventById(id);
        console.log(`token: ${json.tokenConferencia}`);
        const conference = await api.salvarNoConferencias(json.tokenConferencia, item.titulo, item.resumo, item.authors);
        console.log(conference);

        if (conference.id) {
          const newData = {
            ...item.dataOriginal,
            content: {
              ...item.dataOriginal.content,
              linkConferencia: `https://conferencias.unb.br/index.php/iniciacaocientifica/${json.tokenConferencia}/paper/view/${conference.id}`,
            },
          };

          await api.updateArticle(item.dataOriginal._id, newData.content, item.dataOriginal.authors);
        }

        // Atualiza o estado do progresso
        setProgress(((i + 1) / novoArray.length) * 100);
      }
    } catch (error) {
      setError({ error: { msg: "Erro ao tentar acessar a função sendToConference" } });
    } finally {
      setUploading(false); // Indica que o envio foi concluído
      setProgress(0); // Reinicia o progresso para 0
    }
  } else {
    console.error('O array novoArray está vazio.');
  }
};





return (
<>

<Modal_article 
  formInfo={formInfo} 
  formData={formData} 
  setFormInfo={setFormInfo} 
  setFormData={setFormData} 
  setRefresh={setRefresh} 
  refresh={refresh} 
  setSearchValue = {setSearchValue} 
  getEventInfo = {getEventInfo}
  eventosPermitindoSubmissao = {eventosPermitindoSubmissao}
  admin = {true}
  cpfUser = {cpf}
/>
<Modal_menu eventInfo={eventInfo} menu={menu} />
<Modal_upload  />
<HeaderAdmin />
  <div className="body-admin">
    <MenuAdmin menu = {menu}/>
    <div className="container-admin container-admin-menu">
      <h3 className='mb-3'>Resumos</h3>
      <section id="form-submissao">
        <div className="header">
          <h4>Lista de Resumos ({filteredData > 0 ?filteredData.length: filteredData.length })</h4>
          <div className='actions'>
            <a onClick={(e) => {
              getEventInfo(id)
              openModal(e, 'article');
              resetFormValues('article');
              setSelectedArticle(null);
              setFormData({idEvent:id,content:{},authors:[]});
              }} className="btn btn-primary mr-1" href="">
              <p>Incluir resumo </p>
              </a>
              <a onClick={(e) => {handleDownload(e,data,'Congresso')}} className="btn btn-primary mr-1" href="">
                <p>Excel</p>
              </a>
              <a onClick={(e) => sendToConference(e, novoArray)} className="btn btn-primary mr-1" href="">
                <p>Enviar resumos para o Conferências</p>
              </a>
              <Link to={`/admin/listaResumos/${id}`} className="btn btn-primary mr-1">
                <p>Gerar Html</p>
              </Link>
            {!data[0]&&(
              <a onClick={(e) => {openModal(e, 'upload')}} className="btn btn-primary mr-1" href="">
                <p>Upload UnB</p>
              </a>
            )}
          </div>
          
        </div>
        {
        Object.keys(error).map((key, index) => (
            error[key].msg && (
              <div className="message mt-2" key={index}>
                <div className="error-message border">
                  <p className='font-size-12'>{error[key].msg}</p>
                </div>
              </div>
            )
          ))
    }
    {uploading && (
  <div className="progress-bar-container">
    <p>{`Conclusão em ${progress.toFixed(0)}%`}</p>
    <div className="progress-bar" style={{ width: `${progress}%` }}></div>
  </div>
)}
        <div className="search-input">
          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Buscar"
          />
        </div>
        {filteredData.length > 0 ? (
            <div className="boxes">
              {Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((e, index) => (
                  <div className="box" key={e._id} onClick={(event) => handleBoxClick(event, e._id,e.idEvent)}>
                    <div className='box-content'>
                        <p>{e.content.titulo}</p>
                        {e.authors.map((item, index) => (
                          <span key={index}>
                            {item.type}: {item.name}<br />
                          </span>
                        ))}
                        
                        <div className='status'>
                        {statusMap[e.content.status] && (
                            <>
                            <div className={`icon-ball ${statusMap[e.content.status].className}`} />
                            <p>{statusMap[e.content.status].text}</p>
                            </>
                        )}
                        </div>
                        {Object.keys(error).map((key, index) => error[key].msg && error[key].id === e._id&&(
                        <div className="message mt-2" key={index}>
                            <div className="error-message border">
                                <p className="font-size-12">{error[key].msg}</p>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                ))
              ) : (
                <p>Nenhum resumo encontrado.</p>
              )}
                
            </div>
            ) : (
            <p>Nenhum resumo encontrado.</p>
        )}
      </section>
    </div>
  </div>
</>
);
};

export default Recebimento;
