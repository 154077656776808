import logoSmall from 'svg/logoIC.svg';
import { doLogout } from "helpers/AuthHandler";
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG

//Link
import { Link } from "react-router-dom";
const Header = ()=>{

    const handleLogout = ()=>{
        doLogout();
        window.location.href='/';
    }
return(
<>

     
<header id="headerAdmin" className="header-profile">
    <nav>
    <div className="logo ">
        <Link to="/" className='logo-img logo-img-block'>
            <InlineSVG src={logoSmall} alt="" />
        </Link>
        <div className="title">
            <p>Iniciação Científica</p>
        </div>
    </div>


    <div className="logout">
    <a onClick={handleLogout} className='button-link' href="/admin">Sair</a>
    </div>
    </nav>
</header>
</>
)}

export default Header