import { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';
import iconClose from 'svg/icon_close.svg';
import { closeModal, openModal } from 'helpers/Modal';
import Modal_newPeople from 'pages/Admin/Modal_newPeople';
import { isEmptyArray } from 'formik';

const Modal_ArticleAvaliador = ({ 
  formInfo, 
  formData,
  setFormData,
  setFormInfo,
  setRefresh,
  refresh,
  setSearchValue,
  getEventInfo,
  eventosPermitindoSubmissao,
  admin = false,
  cpfUser = '',
  setDadosResumosCarregados
}
) => {
const api = useAPI();
const [disabled, setDisabled] = useState(false);
const [error, setError] = useState({});
const [cpf, setCpf] = useState('');
const [peopleType, setPeopleType] = useState('');
const [cpfValid, setCpfValid] = useState(false);
const [charCounts, setCharCounts] = useState({});
const [mastrarFormAvaliacao, setMostrarFormAvaliacao] = useState(false);


useEffect(() => {
  const charCountsData = {};
  formInfo.forEach((e) => {
    const charCounterId = `char-counter-${e.labelId}`;
    const textValue = formData.content?formData.content[e.labelId] : '';
    const charCount = textValue?textValue.length:0;
    charCountsData[charCounterId] = charCount;
  });
  setCharCounts(charCountsData);
}, [formInfo, formData]);
const handleCpfValidation = (value) => {
  setCpf(value);
  setCpfValid(cpfValidator(value));
};

const updateAuthors = (name, type, cpf) => {
  setFormData((prevData) => ({
    ...prevData,
    authors: prevData.authors
      ? [...prevData.authors, { name, type, cpf }]
      : [{ name, type, cpf }],
  }));
};

const handlePeople = async (e) => {
  setError({});
  const json = await api.getPeople(cpf);
  if (json.error) {
    openModal(e, 'newPeople');
    setCpf(cpf);
  } else {
    updateAuthors(json.name, peopleType, json.cpf);
    setCpf('');
    setPeopleType('');
  }
};

const handleDelete = (index) => {
  setFormData((prevData) => {
    const updatedAuthors = [...prevData.authors];
    updatedAuthors.splice(index, 1);
    return {
      ...prevData,
      authors: updatedAuthors,
    };
  });
};


const closeAvaliador = async (e) => {
  setMostrarFormAvaliacao(false)
  e.preventDefault();
  setDadosResumosCarregados(false)
  closeModal(e, 'articleAvaliador');
  setRefresh(true)
  if (formData.content) {
    formData.content.status = 'Aguardando avaliação'
    formData.content.avaliador = ''
    formData.content['1_nota'] = 0
    formData.content['2_nota'] = 0
    formData.content['3_nota'] = 0
    formData.content['4_nota'] = 0
    formData.content['5_nota'] = 0
    await api.updateArticle(formData._id,formData.content,formData.authors);
    //closeModal(e, 'articleAvaliador');
  }
  
};

const handleSubmit = async (e) => {
  e.preventDefault();
  formData.content.status = 'Avaliado'
  formData.content.avaliador = cpfUser
  console.log(cpfUser)
  await api.updateArticle(formData._id,formData.content,formData.authors);
  closeModal(e,'articleAvaliador')
  setRefresh(true)
  setMostrarFormAvaliacao(false)
};
const iniciarAvaliacao = async (e) => {
  setMostrarFormAvaliacao(false)
  e.preventDefault();
  setError({})
  try {
    const st = 'em Avaliação'
    const articlesJson = await api.getListArticleByCPFAvaliador(formData?.idEvent,st,cpfUser);
    
    if (articlesJson[0]) {
      const encontrado = articlesJson.some(article => {
        return article._id === formData._id && article.content.avaliador === cpfUser;
      });
      if (encontrado) {
        setMostrarFormAvaliacao(true)
      } else {
        //setError({error:{msg:"Ops! Outro avaliador já está avaliando este trabalho. Aguarde que iremos te redirecionar!"}})
        setRefresh(true)
        closeModal(e,'articleAvaliador')
      }
      //console.log(articlesJson)
      
    }else {
      //setError({error:{msg:"Ops! Outro avaliador já está avaliando este trabalho. Aguarde que iremos te redirecionar!"}})
      setRefresh(true)
      closeModal(e,'articleAvaliador')
    }
    
    //closeModal(e,'articleAvaliador')
    //setRefresh(true)
      
  } catch (error) {
      console.error('Erro ao obter artigos:', error);
      return [];
  }
  
};
const editalOptions = ['PIBIC EM', 'PIBIC/EM', 'PIBITI/JR', 'PIBITI Junior'];
const options = formData?.dataEvent?.settings?.formularios?.formulario_artigo;

const desiredElement = options?.find(element => element.labelId === "turnoApresentação");
const turno = formData?.content?.turnoApresentação
const grandeArea = formData?.content?.grandeArea
const diaApresentacao = desiredElement?.options?.find(element => element.title === turno && element.linkID === grandeArea);

  return (
    <>
      <div className="container-modal articleAvaliador ">
        
        <div className="modal">
        {Object.keys(error).map((key, index) =>
                  error[key].msg ? (
                    <div className="message mb-2" key={index}>
                      <div className="error-message border">
                        <p className="font-size-12">{error[key].msg}</p>
                      </div>
                    </div>
                  ) : null
                )}
          
          <div className="content">
          
            <div className='header-modal mb-2'>
            
              <h3 className='mb-3'></h3>
              <button
                
                className="btn btn-primary mr-2 w-100 btn-danger"
                onClick={async(e) => {
                  
                  await closeAvaliador(e)
                  
                  setFormData({});
                  setFormInfo([]);
                }}
              >
                <p>DEVOLVER</p>
              </button>
              
            </div>
            <div className="article ">
              <h3>{formData?.content?.titulo}</h3>
              {formData?.authors?.map((e) => (
                <>
                <span key={e.cpf}>{e.name} ({e.type})</span> <br></br>
                </>
              ))}
              
              {formData?.content?.status !== 'Avaliado'&&(
                <div className='apresentacao'>
                <div className='content'>
                  
                  <p>
                  {formData?.content?.edital} - {formData?.content?.grandeArea} - {formData?.content?.subArea}<br></br>
                  {formData?.content?.poster && editalOptions.includes(formData.content.edital) ? (
                    <>BALCÃO Nº:</>) : (<>PÔSTER Nº:</>)} <span>{formData?.content?.poster?formData.content?.poster:'Não definido'}</span><br></br>
                  DIA DA APRESENTAÇÃO: <span>{diaApresentacao?.dia || 'Não definido'}</span><br />
                  TURNO: <span>{formData?.content?.turnoApresentação}</span><br></br>
                  
                  </p>
                </div>
                
                <a onClick={(e) => {iniciarAvaliacao(e)}}  className=" btn btn-checkin" href="">
                        <p>Iniciar avaliação</p>
                      </a>
                
                </div>
              )}
              
              
            </div>
         {mastrarFormAvaliacao &&
         <div className="content-form">
              
         <form id="articleAvaliador" onSubmit={handleSubmit} className="mt-4" autoComplete="off">
         
           {formInfo?.map((e, index) => {
             const charCounterId = `char-counter-${e.labelId}`;
             const textValue = formData[e.labelId] || '';
             const charCount = textValue.length;
             const filteredNotas = formInfo?.filter(item => item.type === 'option-nota');
             let totalNotas = 0;

             filteredNotas.forEach(item => {
               const labelId = item.labelId;
               totalNotas += eval(formData.content?formData.content[labelId]:0) || 0;
             });
             return (
               <div className="mt-3" key={index}>
                 <label>
                   {e.type === 'text'&& e.view ? (
                     <>
                     <p>{e.labelTitle} <span className="char-counter">({charCounts[charCounterId] || 0})</span>
                       </p>
                       <input
                         className={error.title ? 'error-input' : ''}
                         maxLength={e.max}
                         required={e.required}
                         disabled={e.readonly}
                         type="text"
                         placeholder={e.placeholder}
                         value={formData.content?formData.content[e.labelId] : ''}
                         onChange={(event) =>
                           setFormData((prevData) => ({
                             ...prevData,
                             content: {
                               ...prevData.content,
                               [e.labelId]: event.target.value,
                             },
                           }))
                         }
                       />
                       
                     </>
                   ) : ("")}
                   {e.type === 'url'&& e.view ? (
                     <>
                     <p>{e.labelTitle}</p>
                       <input
                         className={error.title ? 'error-input' : ''}
                         required={e.required}
                         disabled={e.readonly}
                         maxLength={e.max}
                         type="url"
                         placeholder={e.placeholder}
                         value={formData.content?formData.content[e.labelId] : ''}
                         onChange={(event) =>
                           setFormData((prevData) => ({
                             ...prevData,
                             content: {
                               ...prevData.content,
                               [e.labelId]: event.target.value,
                             },
                           }))
                         }
                       />
                       
                     </>
                   ) : ("")}
                   {e.type === 'longText'&& e.view ? (
                     <>
                     <p>{e.labelTitle} <span className="char-counter">({charCounts[charCounterId] || 0})</span>
                   </p>
                       <textarea
                       className={error.title ? 'error-input' : ''}
                       maxLength={e.max}
                       required={e.required}
                       disabled={e.readonly}
                       type="text"
                       placeholder={e.placeholder}
                       value={formData.content?formData.content[e.labelId] : ''}
                       onChange={(event) =>
                         setFormData((prevData) => ({
                           ...prevData,
                           content: {
                             ...prevData.content,
                             [e.labelId]: event.target.value,
                           },
                         }))
                       }
                     />
                       
                     </>
                   ) : ("")}
                   {e.type === 'option' && e.view ? (
                     <>
                       <p>{e.labelTitle}</p>
                       <select
                         id={e.labelId}
                         disabled={e.readonly}
                         required={e.required}
                         
                         value={formData.content ? formData.content[e.labelId]?.title : ''}
                         onChange={(event) => {
                           setFormData((prevData) => ({
                             ...prevData,
                             content: {
                               ...prevData.content,
                               [e.labelId]: event.target.value,
                             },
                           }));
                           const filtered = formInfo?.filter(item => item.link === e.labelId);
                           
                           if (!isEmptyArray(filtered)) {
                             filtered.map((item) => {
                               setFormData((prevData) => ({
                                 ...prevData,
                                 content: {
                                   ...prevData.content,
                                   [item.labelId]: "",
                                 },
                               }));
                               
                               return null; // Certifique-se de retornar algo no .map()
                             });
                           }
                         }}
                       >
                         <option value={formData.content ? formData.content[e.labelId] :''}>
                           {formData.content ? formData.content[e.labelId] : 'Selecione uma opção'}
                         </option>
                         {e.options.map((element, index) => (
                           <option key={index} value={element.title}>
                             {element.title}
                           </option>
                         ))}
                       </select>
                     </>
                   ) : null}
                   {e.type === 'option-link'&& e.view ? (
                     <>
                     
                     <p>{e.labelTitle}</p>
                     <select id={e.labelId} 
                       required={e.required}
                       disabled={e.readonly}
                       value={formData.content?formData.content[e.labelId] : ''}
                       onChange={(event) =>{
                         setFormData((prevData) => ({
                           ...prevData,
                           content: {
                             ...prevData.content,
                             [e.labelId]: event.target.value,
                           },
                         }))
                       }
                       }
                     >
                     <option value={formData.content?formData.content[e.labelId] : ''}>
                       {formData.content?formData.content[e.labelId] : 'Selecione uma opção'}
                     </option>
                     {e.options.map((element, index) => {
                       if (formData.content && formData.content[e.link] === element.linkID) {
                         return (
                           <option key={index} value={element.title}>
                             {element.title}
                           </option>
                         );
                       }
                       return null; // Return null for cases where the condition is not met
                     })}
                   </select>
   
                       
                     </>
                   ) : ("")}
                   {e.type === 'option-nota' && e.view ? (
                     <>
                       <p>{e.labelTitle}</p>
                       <div className='slider'>
                       <input type="range" id="slider" name="slider" min="0" max="20" step="1"
                       required={e.required}
                       disabled={e.readonly}
                       value={formData.content?formData.content[e.labelId]?formData.content[e.labelId] : "0": "0"}
                       onChange={(event) =>
                         setFormData((prevData) => ({
                           ...prevData,
                           content: {
                             ...prevData.content,
                             [e.labelId]: event.target.value,
                             premiacao:""
                           },
                         }))
                       }
                       
                       />
                       <span id="sliderValue">{formData.content?formData.content[e.labelId]?formData.content[e.labelId] : "0": "0"}</span>
                       </div>
                     </>
                   ) : null}
                   
                   {e.type === 'option-nota-total' && e.view && (totalNotas) >= 90 ? (
                     <>
                       <p>{e.labelTitle}</p>
                       <select
                         id={e.labelId}
                         required={e.required}
                         disabled={e.readonly}
                         value={formData.content ? formData.content[e.labelId]?.title : ''}
                         onChange={(event) => {
                           setFormData((prevData) => ({
                             ...prevData,
                             content: {
                               ...prevData.content,
                               [e.labelId]: event.target.value,
                             },
                           })); 
                            
                         }}
                       >
                         <option value={formData.content?formData.content[e.labelId] : ''}>
                           {formData.content?formData.content[e.labelId] : 'Selecione uma opção'}
                         </option>
                         {e.options && e.options.length > 0 ? (
                           e.options.map((element, index) => {
                             // Check if the option should be displayed based on totalNotas
                             if (
                               (totalNotas >= 90 && totalNotas < 100 && element.title !== "Indicação a Prêmio Destaque" && element.title !== "Prêmio Destaque") ||
                               (totalNotas === 100 && element.title !== "Prêmio Destaque")
                             ) {
                               return (
                                 <option key={index} value={element.title}>
                                   {element.title}
                                 </option>
                               );
                             }
                             return null; // Skip this option
                           })
                         ) : null}
                       </select>
                     </>
                   ) : null}


                 </label>
               </div>
             );
           })}
           

         </form>
       </div>
         }
              
            {
  formData.content &&
  formData.content["1_nota"] > 0 &&
  formData.content["2_nota"] > 0 &&
  formData.content["3_nota"] > 0 &&
  formData.content["4_nota"] > 0 &&
  formData.content["5_nota"] > 0 && (
    <div className="modal-actions mt-4">
      <button
        form="articleAvaliador"
        disabled={disabled}
        className="btn btn-primary mr-2"
      >
        <p>Finalizar avaliação</p>
      </button>
    </div>
  )
}


            
              
            
            
            
          </div>
          
          
        </div>
      </div>
    
      
      <Modal_newPeople
        cpf={cpf}
        peopleType={peopleType}
        updateAuthors={updateAuthors}
        setCpf={setCpf}
        setPeopleType={setPeopleType}
      />
    </>
  );
};

export default Modal_ArticleAvaliador;
