import React, { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import { openModal } from 'helpers/Modal';
import HeaderAdmin from 'pages/Admin/HeaderAdmin';
import MenuAdmin from 'pages/Admin/MenuAdmin';
import menuAdmin from 'helpers/menuAdmin';
import Modal_menu from 'pages/Admin/Modal_menu';

const Programacao = () => {
  const api = useAPI();
  const { id } = useParams();

  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [eventInfo, setEventInfo] = useState({});

  const [menu, setMenu] = useState([]);
  const [generatingProgram, setGeneratingProgram] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);


  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedInstituicao, setSelectedInstituicao] = useState([]);
  const [selectedEdital, setSelectedEdital] = useState([]);
  const [selectedGrandeArea, setSelectedGrandeArea] = useState([]);
  const [selectedturnoApresentação, setSelectedturnoApresentação] = useState([]);


  const [filteredSum, setFilteredSum] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMenu(menuAdmin(1, id));

        const articlesJson = await api.getListArticle(id);
        setArticles(articlesJson);
      } catch (error) {
        // Handle errors
      }
    };
    fetchData();
  }, [id]);

  // Extract unique values from the data
  const uniqueStatus = articles.length > 0 ? [...new Set(articles.map(article => article.content.status))] : [];
const uniqueInstituicoes = articles.length > 0 ? [...new Set(articles.map(article => article.content.instituicao))] : [];
const uniqueEdital = articles.length > 0 ? [...new Set(articles.map(article => article.content.edital))] : [];
const uniqueGrandeArea = articles.length > 0 ? [...new Set(articles.map(article => article.content.grandeArea))] : [];
const uniqueturnoApresentação = articles.length > 0 ? [...new Set(articles.map(article => article.content.turnoApresentação))] : [];


useEffect(() => {
  const filteredArticles = articles.length > 0 ? articles.filter((article) =>
    (selectedStatus.length === 0 || selectedStatus.includes(article.content.status)) &&
    (selectedInstituicao.length === 0 || selectedInstituicao.includes(article.content.instituicao)) &&
    (selectedEdital.length === 0 || selectedEdital.includes(article.content.edital)) &&
    (selectedGrandeArea.length === 0 || selectedGrandeArea.includes(article.content.grandeArea)) &&
    (selectedturnoApresentação.length === 0 || selectedturnoApresentação.includes(article.content.turnoApresentação))
    // Inclua condições semelhantes para outros filtros (edital, grandeArea, etc.)
  ) : [];

  const sum = filteredArticles.length;
  setFilteredArticles(filteredArticles)
  setFilteredSum(sum);
}, [selectedStatus, selectedInstituicao, selectedEdital, selectedGrandeArea, selectedturnoApresentação /* adicione outros filtros */, articles]);

const calculateTotal = (option,key) => {
  const filteredArticles = articles.length > 0 ? articles.filter((article) =>
    (selectedStatus.length === 0 || selectedStatus.includes(article.content.status)) &&
    (selectedInstituicao.length === 0 || selectedInstituicao.includes(article.content.instituicao)) &&
    (selectedEdital.length === 0 || selectedEdital.includes(article.content.edital)) &&
    (selectedGrandeArea.length === 0 || selectedGrandeArea.includes(article.content.grandeArea)) &&
    (selectedturnoApresentação.length === 0 || selectedturnoApresentação.includes(article.content.turnoApresentação))
    // Inclua condições semelhantes para outros filtros (edital, grandeArea, etc.)
  ) : [];
  const totalSelected = filteredArticles.filter(article => article.content[key] === option).length;
  return `${totalSelected}`;
};

const gerarProgramacao = async (e, articles) => {
  e.preventDefault();
  // Inicie o processo de geração
  setGeneratingProgram(true);
  setGenerationProgress(0); // Começa em 0%

  try {
      // Ordenar por subArea e depois por orientadores
      articles.sort((a, b) => {
        // Ordenar por subArea
        if (a.content.subArea < b.content.subArea) return -1;
        if (a.content.subArea > b.content.subArea) return 1;

        // Se as subAreas forem iguais, ordenar por orientadores
        const orientadorA = a.authors.find(author => author.type === 'orientador');
        const orientadorB = b.authors.find(author => author.type === 'orientador');

        if (orientadorA && orientadorB) {
          if (orientadorA.cpf < orientadorB.cpf) return -1;
          if (orientadorA.cpf > orientadorB.cpf) return 1;
        }

        return 0;
      });
      let posterNumber = 101;

      for (const [index, article] of articles.entries()) {
        article.content.poster = posterNumber;
        await api.updateArticle(article._id, article.content, article.authors);
        posterNumber++;
  
        // Atualize o progresso a cada iteração
        const progress = Math.round(((index + 1) / articles.length) * 100);
        setGenerationProgress(progress);
      }
  } catch (error) {
    // Lide com erros, se necessário
  } finally {
    // Finalize o processo de geração, independentemente do resultado
    setGeneratingProgram(false);
  }




};



  return (
    <>
        <Modal_menu eventInfo={eventInfo} menu={menu} />

      <HeaderAdmin />
      <div className="body-admin">
        <MenuAdmin menu={menu} />
        <div className="container-admin container-admin-menu">
          <h3 className="mb-3">Programação</h3>
          
          <section id='dashboard'>
            
            <div className="dashboard-summary box">
              <h4>Total: {filteredSum} resumos</h4>
              
            </div>
            <div className="dashboard-filters">
              <div className="filter-section">
                <h4>Status</h4>
                <div className='label-options'>
                <label>
                  <input
                    type="checkbox"
                    value="Selecionar todos"
                    checked={selectedStatus.length === uniqueStatus.length}
                    onChange={() => setSelectedStatus(selectedStatus.length === uniqueStatus.length ? [] : uniqueStatus)}
                  />
                  <p>Selecionar todos</p>
                </label>
                {uniqueStatus.map(status => (
                  
                  <label key={status}>
                    <input
                      type="checkbox"
                      value={status}
                      checked={selectedStatus.includes(status)}
                      onChange={() => {
                        if (selectedStatus.includes(status)) {
                          setSelectedStatus(selectedStatus.filter(s => s !== status));
                        } else {
                          setSelectedStatus([...selectedStatus, status]);
                        }
                      }}
                    />
                    <p>{status ? `${status} ${calculateTotal(status,'status')}` : `sem registro ${calculateTotal(status,'status')}`}</p>
                  </label>
                ))}
                </div>
              </div>
              <div className="filter-section">
                <h4>Instituição</h4>
                <div className='label-options'>
                <label>
                  <input
                    type="checkbox"
                    value="Selecionar todos"
                    checked={selectedInstituicao.length === uniqueInstituicoes.length}
                    onChange={() => setSelectedInstituicao(selectedInstituicao.length === uniqueInstituicoes.length ? [] : uniqueInstituicoes)}
                  />
                  <p>Selecionar todos</p>
                </label>
                {uniqueInstituicoes.map(instituicao => (
                  <label key={instituicao}>
                    <input
                      type="checkbox"
                      value={instituicao}
                      checked={selectedInstituicao.includes(instituicao)}
                      onChange={() => {
                        if (selectedInstituicao.includes(instituicao)) {
                          setSelectedInstituicao(selectedInstituicao.filter(i => i !== instituicao));
                        } else {
                          setSelectedInstituicao([...selectedInstituicao, instituicao]);
                        }
                      }}
                    />
                    <p>{instituicao ? `${instituicao} ${calculateTotal(instituicao,'instituicao')}` : `sem registro ${calculateTotal(instituicao,'instituicao')}`}</p>
                    
                  </label>
                ))}
                </div>
              </div>
              <div className="filter-section">
                <h4>Edital</h4>
                <div className='label-options'>
                <label>
                  <input
                    type="checkbox"
                    value="Selecionar todos"
                    checked={selectedEdital.length === uniqueEdital.length}
                    onChange={() => setSelectedEdital(selectedEdital.length === uniqueEdital.length ? [] : uniqueEdital)}
                  />
                  <p>Selecionar todos</p>
                </label>
                {uniqueEdital.map(edital => (
                  <label key={edital}>
                    <input
                      type="checkbox"
                      value={edital}
                      checked={selectedEdital.includes(edital)}
                      onChange={() => {
                        if (selectedEdital.includes(edital)) {
                          setSelectedEdital(selectedEdital.filter(i => i !== edital));
                        } else {
                          setSelectedEdital([...selectedEdital, edital]);
                        }
                      }}
                    />
                    <p>{edital ? `${edital} ${calculateTotal(edital,'edital')}` : `sem registro ${calculateTotal(edital,'edital')}`}</p>
                    
                  </label>
                ))}
                </div>
              </div>
              <div className="filter-section">
                <h4>Grande Área</h4>
                <div className='label-options'>
                <label>
                  <input
                    type="checkbox"
                    value="Selecionar todos"
                    checked={selectedGrandeArea.length === uniqueGrandeArea.length}
                    onChange={() => setSelectedGrandeArea(selectedGrandeArea.length === uniqueGrandeArea.length ? [] : uniqueGrandeArea)}
                  />
                  <p>Selecionar todos</p>
                </label>
                {uniqueGrandeArea.map(grandeArea => (
                  <label key={grandeArea}>
                    <input
                      type="checkbox"
                      value={grandeArea}
                      checked={selectedGrandeArea.includes(grandeArea)}
                      onChange={() => {
                        if (selectedGrandeArea.includes(grandeArea)) {
                          setSelectedGrandeArea(selectedGrandeArea.filter(i => i !== grandeArea));
                        } else {
                          setSelectedGrandeArea([...selectedGrandeArea, grandeArea]);
                        }
                      }}
                    />
                    <p>{grandeArea ? `${grandeArea} ${calculateTotal(grandeArea,'grandeArea')}` : `sem registro ${calculateTotal(grandeArea,'grandeArea')}`}</p>
                    
                  </label>
                ))}
                </div>
              </div>
              <div className="filter-section">
                <h4>Turno de Apresentação</h4>
                <div className='label-options'>
                <label>
                  <input
                    type="checkbox"
                    value="Selecionar todos"
                    checked={selectedturnoApresentação.length === uniqueturnoApresentação.length}
                    onChange={() => setSelectedturnoApresentação(selectedGrandeArea.length === uniqueturnoApresentação.length ? [] : uniqueturnoApresentação)}
                  />
                  <p>Selecionar todos</p>
                </label>
                {uniqueturnoApresentação.map(turnoApresentação => (
                  <label key={turnoApresentação}>
                    <input
                      type="checkbox"
                      value={turnoApresentação}
                      checked={selectedturnoApresentação.includes(turnoApresentação)}
                      onChange={() => {
                        if (selectedturnoApresentação.includes(turnoApresentação)) {
                          setSelectedturnoApresentação(selectedturnoApresentação.filter(i => i !== turnoApresentação));
                        } else {
                          setSelectedturnoApresentação([...selectedturnoApresentação, turnoApresentação]);
                        }
                      }}
                    />
                    <p>{turnoApresentação ? `${turnoApresentação} ${calculateTotal(turnoApresentação,'turnoApresentação')}` : `sem registro ${calculateTotal(turnoApresentação,'turnoApresentação')}`}</p>
                    
                  </label>
                ))}
                </div>
              </div>
              {/* ... (similar sections for other filters) */}
            </div>
            
          </section>
          <div className='display-flex'>
          <div className='actions'>
            {selectedStatus.length === 1 &&
              selectedEdital.length > 0 &&
              selectedGrandeArea.length === 1 &&
              selectedturnoApresentação.length === 1 && (
                <>
                  {generatingProgram ? (
                    <p>Gerando programação... {generationProgress}% concluído</p>
                  ) : (
                    <a
                      onClick={(e) => {
                        gerarProgramacao(e, filteredArticles);
                      }}
                      className="btn btn-secondary mr-1"
                      href=""
                    >
                      <p>Gerar programação</p>
                    </a>
                  )}
                </>
              )}
          </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Programacao;
