import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "pages/Header";
import Login from "pages/Login";
import LoginUser from "pages/LoginUser";
import NotFound from "pages/NotFound";
import Profile from "pages/Profile";
import ProfileUser from "pages/ProfileUser";

import ListaRecebimento from "pages/Admin/ListaRecebimento/index";
import Programacao from "pages/Admin/Programacao";
import Configuracoes from "pages/Admin/Configuracoes";
import Avaliadores from "pages/Admin/Avaliadores";
import Dashboard from "pages/Admin/Dashboard";
import Certificado from "pages/Certificado";
import Avaliador from "pages/Avaliador";
import PerfilAvaliador from "pages/PerfilAvaliador";
import ListaResumos from "pages/Admin/ListaResumos";


import {RouteHandler} from "helpers/RouteHandler";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginUser />} /> 
        <Route path="*" element={<LoginUser />} /> 
        <Route path="/admin" element={<Login />} />
        
        <Route
          path="/profile"
          element={
            <RouteHandler private>
              <Profile/>
            </RouteHandler>
          }
        />
        <Route path="/profile/user" element={<ProfileUser/>} />
        
        <Route path="/avaliador" element={<Avaliador/>} />
        <Route path="/avaliador/:id/:avaliadorCPF" element={<PerfilAvaliador/>} />
        <Route path="/admin/listaResumos/:id/" element={<ListaResumos/>} />

        <Route path="/admin/listaRecebimento/:id" element={<ListaRecebimento/>} />
        <Route path="/admin/programacao/:id" element={<Programacao/>} />
        <Route path="/admin/configuracoes/:id" element={<Configuracoes/>} />
        <Route path="/admin/avaliadores/:id" element={<Avaliadores/>} />
        <Route path="/admin/dashboard/:id" element={<Dashboard/>} />
        <Route path="/certificado/:tipo/:idArtigoOuIdEvento/:cpf" element={<Certificado/>} />
      </Routes>
    </Router>
  );
};

export default App;
