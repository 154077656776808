import React, { useState, useEffect } from 'react';
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG
import logoExpanded_1 from 'svg/logoIC_dark.svg';
import icon_user from 'svg/icon-user.svg';
import useAPI from 'helpers/API';
import { doLogin } from 'helpers/AuthHandler';
import cpfValidator from 'helpers/cpfValidator';
import { closeModal, openModal } from 'helpers/Modal';
import Modal_newPeople from 'pages/Admin/Modal_newPeople';
import { Link } from "react-router-dom";

const Login = () => {
  const api = useAPI();
  const [cpf, setCpf] = useState('');
  const [cpfValid, setCpfValid] = useState(false);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [peopleType, setPeopleType] = useState(false);
  const handleCpfValidation = (value) => {
    setCpf(value);
    setCpfValid(cpfValidator(value));
  };

  const handleSubmit = async (e)=>{
    e.preventDefault();
    setDisabled(true);
    setError('');
    const json = await api.loginWithCPF(cpf); 
    console.log(json)

    if (json.error) {
        setDisabled(false);
        openModal(e, 'newPeople');
    }else{
        doLogin(json.token);
        window.location.href = '/profile/user'
    }
    setDisabled(false);
}
/****** 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const json = await api.getPeople(cpf);
    //console.log(json)
    if (json.error) {
        console.log(json)
        openModal(e, 'newPeople');
        setCpf(cpf);
    } else {
        const json = await api.loginWithCPF(cpf); 
        //doLogin(json.token);
        //window.location.href = '/profile/user';
        //setCpf('');
    }*/
    /***const json = await api.login(email, password);

    if (json.error) {
      setError(json.error);
    } else {
      doLogin(json.token);
      window.location.href = '/profile';
    }
    setDisabled(false);
    
  };***/

  useEffect(() => {
    // Verifica a presença da classe .light em elementos filhos
    const hasLightClassInDOM = document.querySelector('.light');

    // Define o estilo do background do body com base no resultado
    document.body.style.background = hasLightClassInDOM ? '#F7F7F7' : '#000';
  }, []);

  return (
    <>
    <Modal_newPeople
    cpf={cpf}
    setCpf={setCpf}
    />
    <div className='light'>
    <section className=" container container-signin-signup ">
      <div className="signin ">
        <div className="logo">
            
          <a href=""><InlineSVG src={logoExpanded_1} alt="" /></a>
        </div>
        {Object.keys(error).map((key, index) => (
          error[key].msg && (
            <div className="message mt-2" key={index}>
              <div className="error-message border">
                <p className='font-size-12'>{error[key].msg}</p>
              </div>
            </div>
          )
        ))}
        {
            !cpfValid && cpf.length > 0 &&(
                <div className="message mt-2" >
              <div className="error-message border">
                <p className='font-size-12'>Digite um CPF válido</p>
              </div>
            </div>
            )
        }
        <form onSubmit={handleSubmit} className="mt-2 light-form" autoComplete="off">
          <label className="input-container">
            <div className="input-icon ">
              <InlineSVG src={icon_user} alt="" />
            </div>
            <input
              className={error.title ? 'error-input' : ''}
              type="text"
              placeholder="Digite seu CPF"
              value={cpf}
              onChange={(e) => handleCpfValidation(e.target.value)}
            />
          </label>
          {
            cpfValid && cpf &&(
                <button disabled={disabled} className="btn btn-primary w-100 mt-4">Entrar</button>
            )
          }
        </form>
        
      </div>
      <a href="/admin" className='linkAdmin'>
            <span>Perfil de Administrador</span>
      </a>
    </section>
    </div>
  </>
  );
}

export default Login;
