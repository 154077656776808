function emailValidator(email) {
  if (!email) {
    return false;
  }

  // Expressão regular para validar o formato do email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o email corresponde ao formato esperado
  if (!emailRegex.test(email)) {
    return false;
  }

  return true;
}

module.exports = emailValidator;
