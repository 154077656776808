import Modal_editMyProfile from 'pages/Modal/editMyProfile';
import Modal_article from 'pages/Admin/Modal_Article';
import Modal_viewArticle from 'pages/Admin/Modal_viewArticle';

import { resetFormValues } from "helpers/resetForm";
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG
import {closeModal, openModal, disableScroll, enableScroll} from 'helpers/Modal';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import logoSmall from 'svg/logoIC_light.svg';
import { doLogout } from "helpers/AuthHandler";

//Link
import { Link } from "react-router-dom";
const Profile = ()=>{
const api = useAPI();
//Dados do Perfil do Usuário
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [cpf, setCpf] = useState('');
const [events,setEvents] = useState([])
const [articles,setArticles] = useState([])
const [error, setError] = useState({});
const [searchValue, setSearchValue] = useState('');
const [formInfo, setFormInfo] = useState([]);
const [formData, setFormData] = useState({});
const [refresh, setRefresh] = useState({});
const [eventInfo, setEventInfo] = useState({});
const [data, setData] = useState([]);
const [selectedArticle, setSelectedArticle] = useState(null); // Estado para armazenar o item selecionado
const [eventosPermitindoSubmissao,seteventosPermitindoSubmissao] =useState([])

useEffect(() => {
    const fetchData = async () => {
        setRefresh(false)
        try {
            //Dados do Perfil do usuário
            const profileJson = await api.getMyProfile();
            setName(profileJson.name);
            setEmail(profileJson.email);
            setCpf(profileJson.cpf);
            //Eventos
            const eventos = await api.getAllEvents();
            const eventosAceitandoResumos = eventos.filter(item => 
                item.settings?.controllers?.permitirEnvio
                );
            seteventosPermitindoSubmissao(eventosAceitandoResumos);
            //Artigos do usuário
            const articlesJson = await api.getListArticleByCPF(profileJson.cpf);
            setArticles(articlesJson);
            const dataWithAuthorsInfo = await incluirInfoAutoresEevento(articlesJson);
            setData(dataWithAuthorsInfo);
            
        } catch (error) {
            // Lidar com erros, se necessário
        }
    };
    fetchData();
}, [refresh,formData]);
//Altera o estilo de dark para light
useEffect(() => {
    // Verifica a presença da classe .light em elementos filhos
    const hasLightClassInDOM = document.querySelector('.light');

    // Define o estilo do background do body com base no resultado
    document.body.style.background = hasLightClassInDOM ? '#F7F7F7' : '#000';
}, []);  
//Inclui informação dos autores e do evento em cada item 
const incluirInfoAutoresEevento = async (bd) => {
    try {
        const newData = await Promise.all(
        bd.map(async (item) => {
            const autoresComInfo = await Promise.all(
            item.authors.map(async (autor) => {
                const dataUser = await api.getPeople(autor.cpf);
                if (dataUser) {
                return { ...autor, name: dataUser.name };
                }
                return autor;
            })
            );
            const dataEvent = await api.getEventById(item.idEvent)
            return { ...item, authors: autoresComInfo,dataEvent };
        })
        );
        return newData;
    } catch (error) {
        console.error("Erro ao incluir informações dos autores:", error);
        return bd;
    }
};
//Altera as variáveis formInfo e eventInfo
const getEventInfo = async (id) => {
    try {
        
        //pega os dados do evento no BD
        const json = await api.getEventById(id);
        //pega o formulário padrão
        const arrayForm = json.settings.formularios.formulario_artigo;
        //pega as regras para alterar o formulário padrão
        const rules = json.settings.formularios.formulario_submissao;
        //altera o formulário padrão de acordo com as regras
        const arrayWithRules = arrayForm.map(item => {
            const rule = rules.find(ruleItem => ruleItem.labelId === item.labelId);
            if (rule) {
                return { ...item, ...rule };
            }
            return item;
        });
        setFormInfo(arrayWithRules); // Espera até que setFormInfo seja concluído
        setEventInfo(json);
        return json; // Retorna os dados processados
    } catch (error) {
        setFormInfo([{}])
        console.error("Error fetching event info:", error);
        throw error; // Lança o erro para ser tratado no bloco then ou catch
    }
}
//Aciona o getEventInfo e se der certo, 
const handleBoxClick = (event, id, idEvent) => {
getEventInfo(idEvent)
.then(async(json) => {
    const selected = await api.getArticleByID(id);
    setFormData(selected)
    //setFormData({});
    //setFormInfo([]);
    if (
        (selected.content.status === "Pendente" || 
        selected.content.status === "Aguardando apresentação") &&
        json.settings?.controllers.permitirEnvio
        ) {
        //data aramazena todos os artigos
        //retorna o artigo selecionado com base no id
        selected.content.status = "Aguardando apresentação"
        //armazena o artigo selecionado
        setSelectedArticle(selected);
        
        //popula a variavel formData para receber os dados do artigo selecionado
        setFormData(selected);
        if(selected.content.instituicao === "UNB"){
            //pega os dados do evento no BD
            const json = await api.getEventById(idEvent);
            //pega o formulário padrão
            const arrayForm = json.settings.formularios.formulario_artigo;
            //pega as regras para alterar o formulário padrão
            const rules = json.settings.formularios.formulario_submissao_unb
            //altera o formulário padrão de acordo com as regras
            const arrayWithRules = arrayForm.map(item => {
                const rule = rules.find(ruleItem => ruleItem.labelId === item.labelId);
                if (rule) {
                    return { ...item, ...rule };
                }
                return item;
            });
            setFormInfo(arrayWithRules);
        }
        //abre o modal
        openModal(event, 'article');
    }else{
        openModal(event, 'viewArticle');
    }
    setSelectedArticle(selected);

     //else {setError([{ id, msg: "Envio de resumo encerrado!" }]);}
})
.catch(error => {
    // Lida com erros na obtenção das informações do evento
    console.error(error);
});
};    
    
    
const handleLogout = ()=>{
    doLogout();
    window.location.href='/';
}

const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
};
// Filtra a lista de resumos com base no valor do input de busca
let filteredData =[]
if(data.length>0){
filteredData = data.filter((item) => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const lowerCaseTitulo = item?.content?.titulo?.toLowerCase() || '';
    const lowerCaseEdital = item?.content?.edital?.toLowerCase() || '';
    const lowerCaseInstituicao = item?.content?.instituicao?.toLowerCase() || '';
    const lowerCaseId = item?._id?.toLowerCase() || '';

    // Função para verificar se o CPF ou email de algum autor inclui o valor de busca
    const authorContainsSearchValue = item.authors?.some((author) => {
    const lowerCaseCpf = author?.cpf?.toLowerCase() || '';
    const lowerCaseEmail = author?.email?.toLowerCase() || '';
    return lowerCaseCpf.includes(lowerCaseSearchValue) || lowerCaseEmail.includes(lowerCaseSearchValue);
    });

    return (
    lowerCaseTitulo.includes(lowerCaseSearchValue) ||
    lowerCaseInstituicao.includes(lowerCaseSearchValue) ||
    lowerCaseEdital.includes(lowerCaseSearchValue) ||

    lowerCaseId.includes(lowerCaseSearchValue) ||
    authorContainsSearchValue
    );
});
}
      
const statusMap = {
"Pendente": { className: "icon-ball-red", text: "Pendente" },
"Aguardando apresentação": { className: "icon-ball-white", text: "Aguardando apresentação" },
"Aguardando avaliação": { className: "icon-ball-orange", text: "Aguardando avaliação" },
"em Avaliação": { className: "icon-ball-yellow", text: "Em Avaliação" },
"Avaliado": { className: "icon-ball-green", text: "Avaliado" }
};
console.log(formData)
return(
<>

<header id="headerAdmin" className="header-profile">
    <nav>
        <div className="logo light"> 
            <Link to="/" className='logo-img logo-img-block'>
                <InlineSVG src={logoSmall} alt="" />
            </Link>
            <div className="title">
                <p>Iniciação Científica</p>
            </div>
        </div>
        <div className="logout light">
            <button onClick={handleLogout} className='button-link' to="/">Sair</button>
        </div>
    </nav>
</header>
<div className='light-form'>
    
    <Modal_editMyProfile/>
    <Modal_article 
        formInfo={formInfo} 
        formData={formData} 
        setFormInfo={setFormInfo} 
        setFormData={setFormData} 
        setRefresh={setRefresh} 
        refresh={refresh} 
        setSearchValue = {setSearchValue} 
        getEventInfo = {getEventInfo}
        eventosPermitindoSubmissao = {eventosPermitindoSubmissao}
        cpfUser = {cpf}
    />
    
    <Modal_viewArticle 
        article={selectedArticle}
        cpf={cpf}
        setRefresh={setRefresh} 
    />
    
</div>
<div className="light body-admin">
    <div className="container-admin container-profile">
        <section id="form-submissao">
            <div className="header">
                <h4>Lista de Resumos ({filteredData > 0 ?filteredData.length: filteredData.length })</h4>
                <div className='actions'>
                    {eventosPermitindoSubmissao[0]&&(
                    <a onClick={async (e) => {
                        openModal(e, 'article');
                        //resetFormValues('article');
                        setSelectedArticle(null);
                        setFormData({content:{status:"Aguardando apresentação"},authors:[]});
                        }} className="btn btn-primary mr-1" href="">
                        <p>Incluir resumo</p>
                    </a>
                    )}
                    <a onClick={(e) => openModal(e, 'editMyProfile')}  className="btn btn-secondary" href="">
                        <p>Meus dados</p>
                    </a>
                </div>
            </div>
            <div className="search-input light-form">
                <input
                    type="text"
                    value={searchValue}
                    onChange={handleSearchChange}
                    placeholder="Buscar"
                />
            </div>
            {filteredData.length > 0 ? (
            <div className="boxes">
                {filteredData.map((e, index) => (
                <div className="box" key={e._id} onClick={(event) => handleBoxClick(event, e._id,e.idEvent)}>
                    <div className='box-content'>
                        
                        <p>{e.content.titulo}</p>
                        <p key={e.dataEvent?._id}>
                            {e.dataEvent?.title}<br></br>
                        </p>
                        <div className='status'>
                            {statusMap[e.content.status] && (
                                <>
                                    <div className={`icon-ball ${statusMap[e.content.status].className}`} />
                                    <p>{statusMap[e.content.status].text}</p>
                                </>
                            )}
                        </div>

                        
                        {
                        e.dataEvent?.settings?.controllers?.permitirCheckin && 
                        e.content?.poster > 100 &&
                        e.content?.status === 'Aguardando apresentação' &&
                        (
                        
                        <div  className=" btn btn-primary" >
                            <p>Registrar presença</p>
                        </div>
                        )}
                        
                    </div>
                </div>
                ))}
            </div>
            ) : (
            <p>Nenhum resumo encontrado.</p>
            )}
        </section>
    </div>
    

</div>
</>
)
};

export default Profile;