export const  openModal = (e,modalName)=> {
    e.preventDefault();
    disableScroll()
    var modal = document.querySelector(`.${modalName}`);
    modal.classList.add('show');
    modal.style.opacity = '0';
    setTimeout(() => {
        modal.style.opacity = '1';
    }, 350);
}
export const  closeModal = (e,modalName)=> {
    e.preventDefault();
    enableScroll()
    var modal = document.querySelector(`.${modalName}`);
    modal.style.opacity = '0';
    setTimeout(() => {
        modal.classList.remove('show');
    }, 350);
    
    
}

export const disableScroll = ()=>  {
    document.body.style.overflowY = 'hidden';
}

export const enableScroll = () => {
    document.body.style.overflowY = '';
    
}