
function cpfValidator(cpf) {
  if(!cpf){
    return false
  }
  cpf = cpf.replace(/\D|\r|\n/g, '');
  
  if (cpf.length !== 11) {
    return false; // CPF deve ter 11 dígitos
  }

  // Verificação dos dígitos verificadores
  const digitos = cpf.split('');
  const digito1 = parseInt(digitos[9]);
  const digito2 = parseInt(digitos[10]);

  // Validação do primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(digitos[i]) * (10 - i);
  }
  let resto = soma % 11;
  let resultado = resto < 2 ? 0 : 11 - resto;
  if (resultado !== digito1) {
    return false;
  }

  // Validação do segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(digitos[i]) * (11 - i);
  }
  resto = soma % 11;
  resultado = resto < 2 ? 0 : 11 - resto;
  if (resultado !== digito2) {
    return false;
  }

  return true;
}

module.exports = cpfValidator;
