export const  resetFormValues = (formId)=> {
  
  if (!document.getElementById(formId)) {
    return
  } 
    const form = document.getElementById(formId);
    const formFields = form.querySelectorAll('input, select, textarea');
  
    formFields.forEach((field) => {
      field.value = '';
    });
}


  