import React, { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import { openModal } from 'helpers/Modal';
import HeaderAdmin from 'pages/Admin/HeaderAdmin';
import MenuAdmin from 'pages/Admin/MenuAdmin';
import menuAdmin from 'helpers/menuAdmin';
import Modal_menu from 'pages/Admin/Modal_menu';
import { Link } from 'react-router-dom';
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG
import logoSmall from 'svg/logoIC_light.svg';
import Modal_article from 'pages/Admin/Modal_Article';

const Dashboard = () => {
  const api = useAPI();
  const { id } = useParams();
  const [eventInfo, setEventInfo] = useState({});
  const [data, setData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [formInfo, setFormInfo] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null); // Estado para armazenar o item selecionado
  const [formData, setFormData] = useState({});

  const [allArticles, setAllArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesEM, setArticlesEM] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [menu, setMenu] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedInstituicao, setSelectedInstituicao] = useState([]);
  const [selectedEdital, setSelectedEdital] = useState([]);
  const [selectedGrandeArea, setSelectedGrandeArea] = useState([]);
  const [selectedturnoApresentação, setSelectedturnoApresentação] = useState([]);
  const [error, setError] = useState({});

  const [grandeArea, setGrandeArea] = useState(null);
  const [sumAguardandoApresentacao, setSumAguardandoApresentacao] = useState(0);

  const [refresh, setRefresh] = useState(false);

  const [filteredSum, setFilteredSum] = useState(0);
  const handleVoltar = () => {
    window.location.href = '/profile';
  };

  const [turno, setTurno] = useState(null);
  const statuses = [
    "Aguardando apresentação",
    "Aguardando avaliação",
    "em Avaliação",
    "Avaliado",
    "Menção Honrosa",
    "Indicação ao Prêmio"
  ];
  const statusMap = {
    "Pendente": { className: "icon-ball-red", text: "Pendente" },
    "Aguardando apresentação": { className: "icon-ball-white", text: "Resumo enviado" },
    "Aguardando avaliação": { className: "icon-ball-orange", text: "Aguardando avaliação" },
    "em Avaliação": { className: "icon-ball-yellow", text: "Em Avaliação" },
    "Avaliado": { className: "icon-ball-green", text: "Avaliado" }
    };
  const [countAguardandoCheckin, setCountAguardandoCheckin] = useState(0);
  const [countAguardandoAvaliacao, setCountAguardandoAvaliacao] = useState(0);
  const [countEmAvaliacao, setCountEmAvaliacao] = useState(0);
  const [countAvaliado, setCountAvaliado] = useState(0);
  const [countMencaoHonrosa, setCountMencaoHonrosa] = useState(0);
  const [countIndicacaoPremio, setCountIndicacaoPremio] = useState(0);

  const [countAguardandoCheckinEM, setCountAguardandoCheckinEM] = useState(0);
  const [countAguardandoAvaliacaoEM, setCountAguardandoAvaliacaoEM] = useState(0);
  const [countEmAvaliacaoEM, setCountEmAvaliacaoEM] = useState(0);
  const [countAvaliadoEM, setCountAvaliadoEM] = useState(0);
  const [countMencaoHonrosaEM, setCountMencaoHonrosaEM] = useState(0);
  const [countIndicacaoPremioEM, setCountIndicacaoPremioEM] = useState(0);
  const validConditions = {
    A: { grandeArea: "Artes e Humanidades", turnoApresentação: "matutino" },
    B: { grandeArea: "Artes e Humanidades", turnoApresentação: "vespertino" },
    C: { grandeArea: "Saúde e Vida", turnoApresentação: "matutino" },
    D: { grandeArea: "Saúde e Vida", turnoApresentação: "vespertino" },
    E: { grandeArea: "Exatas e Tecnológicas", turnoApresentação: "matutino" }
  };
//Inclui informação dos autores e do evento em cada item 
const incluirInfoAutoresEevento = async (bd) => {
  try {
    
    const listaDeUsuarios = await api.getListPeople();
    
    const newData = bd.map((item) => {
      const autoresComInfo = item.authors.map((autor) => {
        const usuario = listaDeUsuarios.find((user) => user.cpf === autor.cpf);
        if (usuario) {
          return { ...autor, name: usuario.name, email: usuario.email };
              }
              return autor;
      });

          return { ...item, authors: autoresComInfo };
    });
      return newData;
  } catch (error) {
      console.error("Erro ao incluir informações dos autores:", error);
      return bd;
  }
};
useEffect(() => {
  const fetchData = async () => {
    try {
      setRefresh(false);
      setMenu(menuAdmin(4, id));

      const articlesJson = await api.getListArticle(id);
      console.log(articlesJson)
      const filteredAllArticles = articlesJson.filter(article => {
        const { poster, grandeArea, turnoApresentação } = article.content;
        const conditions = validConditions[turno];
        return (
          conditions &&
          grandeArea === conditions.grandeArea &&
          turnoApresentação === conditions.turnoApresentação
        );
      });

      const data = await incluirInfoAutoresEevento(articlesJson);
      setData(data);

      const filteredArticles = articlesJson.filter(article => {
        const { edital, poster, grandeArea, turnoApresentação } = article.content;
        const validEdital = !["PIBIC/EM", "PIBIC EM", "PIBITI/JR", "PIBITI Junior"].includes(edital);
        const conditions = validConditions[turno];
        return (
          poster > 0 &&
          validEdital &&
          conditions &&
          grandeArea === conditions.grandeArea &&
          turnoApresentação === conditions.turnoApresentação
        );
      });

      const filteredArticlesEM = articlesJson.filter(article => {
        const { edital, poster, grandeArea, turnoApresentação } = article.content;
        const validEdital = ["PIBIC/EM", "PIBIC EM", "PIBITI/JR", "PIBITI Junior"].includes(edital);
        const conditions = validConditions[turno];
        return (
          validEdital &&
          conditions &&
          grandeArea === conditions.grandeArea &&
          turnoApresentação === conditions.turnoApresentação
        );
      });

      setAllArticles(filteredAllArticles);
      setArticles(filteredArticles);
      setArticlesEM(filteredArticlesEM);
        // Calculate the sum of articles with status "Aguardando apresentação"
        const sumAguardando = filteredArticles.reduce((sum, article) => {
          if (article.content.status === "Aguardando apresentação") {
            return sum + 1;
          }
          return sum;
        }, 0);
        setSumAguardandoApresentacao(sumAguardando);
        /****** GERANDO DADOS PARA OS PÔSTERES ********/
        const statusCounts = statuses.reduce((counts, status) => {
          counts[status] = filteredArticles.reduce((sum, article) => {
            if (article.content.status === status) {
              return sum + 1;
            }
            return sum;
          }, 0);
          return counts;
        }, {});
      
        const {
          "Aguardando apresentação": countAguardandoCheckin,
          "Aguardando avaliação": countAguardandoAvaliacao,
          "em Avaliação": countEmAvaliacao,
          "Avaliado": countAvaliado
        } = statusCounts;
      
        const countMencaoHonrosa = filteredArticles.filter(
          article => article.content.premiacao === "Menção Honrosa"
        ).length;
      
        const countIndicacaoPremio = filteredArticles.filter(
          article => article.content.premiacao === "Indicação a Prêmio Destaque"
        ).length;

        // Atualize os estados das contagens
        setCountAguardandoCheckin(countAguardandoCheckin);
        setCountAguardandoAvaliacao(countAguardandoAvaliacao);
        setCountEmAvaliacao(countEmAvaliacao);
        setCountAvaliado(countAvaliado);
        setCountMencaoHonrosa(countMencaoHonrosa);
        setCountIndicacaoPremio(countIndicacaoPremio);

        /****** GERANDO DADOS PARA OS BALCÕES ********/
        const statusCountsEM = statuses.reduce((counts, status) => {
          counts[status] = filteredArticlesEM.reduce((sum, article) => {
            if (article.content.status === status) {
              return sum + 1;
            }
            return sum;
          }, 0);
          return counts;
        }, {});
      
        const {
          "Aguardando apresentação": countAguardandoCheckinEM,
          "Aguardando avaliação": countAguardandoAvaliacaoEM,
          "em Avaliação": countEmAvaliacaoEM,
          "Avaliado": countAvaliadoEM
        } = statusCountsEM;
      
        const countMencaoHonrosaEM = filteredArticlesEM.filter(
          article => article.content.premiacao === "Menção Honrosa"
        ).length;
      
        const countIndicacaoPremioEM = filteredArticlesEM.filter(
          article => article.content.premiacao === "Indicação a Prêmio Destaque"
        ).length;
          console.log(filteredArticlesEM)
        // Atualize os estados das contagens
        setCountAguardandoCheckinEM(countAguardandoCheckinEM);
        setCountAguardandoAvaliacaoEM(countAguardandoAvaliacaoEM);
        setCountEmAvaliacaoEM(countEmAvaliacaoEM);
        setCountAvaliadoEM(countAvaliadoEM);
        setCountMencaoHonrosaEM(countMencaoHonrosaEM);
        setCountIndicacaoPremioEM(countIndicacaoPremioEM);
      } catch (error) {
        // Trate os erros aqui
        console.error('Erro durante a busca de dados:', error);
      }
    };
  
    fetchData();
  }, [id,turno,refresh]);
  
  const subAreasTotals = {};
allArticles.forEach(article => {
  if (article.content.status === "Aguardando avaliação") {
    const subArea = article.content.subArea;
    if (!subAreasTotals[subArea]) {
      subAreasTotals[subArea] = 1;
    } else {
      subAreasTotals[subArea]++;
    }
  }
});

// Convert subAreasTotals object to an array of objects
const subAreasArray = Object.keys(subAreasTotals).map(subArea => ({
  subArea,
  total: subAreasTotals[subArea]
}));

// Sort the array based on the 'total' property in descending order
subAreasArray.sort((a, b) => b.total - a.total);

const ArticlesMencaoHonrosa = articles.filter((item) => item.content.premiacao === "Menção Honrosa")
const ArticlesPremioDestaque = articles.filter((item) => item.content.premiacao === "Indicação a Prêmio Destaque")

const ArticlesMencaoHonrosaEM = articlesEM.filter((item) => item.content.premiacao === "Menção Honrosa")
const ArticlesPremioDestaqueEM = articlesEM.filter((item) => item.content.premiacao === "Indicação a Prêmio Destaque")

console.log(data)
//Altera as variáveis formInfo e eventInfo
const getEventInfo = async (id) => {
  try {
      //pega os dados do evento no BD
      const json = await api.getEventById(id);
      //pega o formulário padrão
      const arrayForm = json.settings.formularios.formulario_artigo;
      //pega as regras para alterar o formulário padrão
      const rules = json.settings.formularios.formulario_congresso;
      //altera o formulário padrão de acordo com as regras
      const arrayWithRules = arrayForm.map(item => {
          const rule = rules.find(ruleItem => ruleItem.labelId === item.labelId);
          if (rule) {
              return { ...item, ...rule };
          }
          return item;
      });
      setFormInfo(arrayWithRules); // Espera até que setFormInfo seja concluído
      setEventInfo(json);
  } catch (error) {
      console.error("Error fetching event info:", error);
      throw error; // Lança o erro para ser tratado no bloco then ou catch
  }
}
//Aciona o getEventInfo e se der certo, 
const handleBoxClick = (event, id, idEvent) => {
  getEventInfo(idEvent)
      .then(async(json) => {
          //data aramazena todos os artigos
              //retorna o artigo selecionado com base no id
              const selected = await api.getArticleByID(id);
              //armazena o artigo selecionado
              setSelectedArticle(selected);
              //abre o modal
              openModal(event, 'article');
              //popula a variavel formData para receber os dados do artigo selecionado
              setFormData(selected);
      })
      .catch(error => {
          // Lida com erros na obtenção das informações do evento
          console.error(error);
      });
};    
const handleSearchChange = (e) => {
  setSearchValue(e.target.value);
};
// Filtra a lista de resumos com base no valor do input de busca
let filteredData =[]
if(data.length>0){
  filteredData = data.filter((item) => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const lowerCaseTitulo = item?.content?.titulo?.toLowerCase() || '';
    const lowerCaseEdital = item?.content?.edital?.toLowerCase() || '';
    const lowerCaseAvaliador = item?.content?.avaliador?.toLowerCase() || '';

    const lowerCaseInstituicao = item?.content?.instituicao?.toLowerCase() || '';
    const lowerCaseId = item?._id?.toLowerCase() || '';
    const lowerCasePoster = (item?.content?.poster || '').toString().toLowerCase();
    // Função para verificar se o CPF ou email de algum autor inclui o valor de busca
    const authorContainsSearchValue = item.authors?.some((author) => {
    const lowerCaseEmail = author?.email?.toLowerCase() || '';
    const lowerCaseNome = author?.name?.toLowerCase() || '';

    return  lowerCaseEmail.includes(lowerCaseSearchValue) ||lowerCaseNome.includes(lowerCaseSearchValue)
    ;
    });
  
    return (
    lowerCaseTitulo.includes(lowerCaseSearchValue) ||
    lowerCaseInstituicao.includes(lowerCaseSearchValue) ||
    lowerCaseEdital.includes(lowerCaseSearchValue) ||
    lowerCasePoster.includes(lowerCaseSearchValue) ||
    lowerCaseId.includes(lowerCaseSearchValue) ||
    lowerCaseAvaliador.includes(lowerCaseSearchValue) ||
    authorContainsSearchValue
    );
  });
}






//Altera o estilo de dark para light
useEffect(() => {
  // Verifica a presença da classe .light em elementos filhos
  const hasLightClassInDOM = document.querySelector('.light');

  // Define o estilo do background do body com base no resultado
  document.body.style.background = hasLightClassInDOM ? '#F7F7F7' : '#000';
}, []); 
useEffect(() => {
  const getEventInfo = async (id) => {
    const json = await api.getEventById(id);
    setEventInfo(json);
  };
  getEventInfo(id);
}, []);
  return (
    <>
    <div className='light-form'>
    <Modal_article 
      formInfo={formInfo} 
      formData={formData} 
      setFormInfo={setFormInfo} 
      setFormData={setFormData} 
      setRefresh={setRefresh} 
      refresh={refresh} 
      setSearchValue = {setSearchValue} 
      getEventInfo = {getEventInfo}
      admin = {true}
      data = {data}
      eventInfo={eventInfo}
    />

    </div>
    
    <Modal_menu eventInfo={eventInfo} menu={menu} />
    <header id="headerAdmin" className="header-profile">
        <nav>
            <div className="logo light"> 
              <Link to="/" className='logo-img logo-img-block'>
                  <InlineSVG src={logoSmall} alt="" />
              </Link>
              <div className='icon-menu'onClick={(e) => {
                    openModal(e, 'menu');
                  }} >
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="title">
                <p>{eventInfo.title}</p>
              </div>
            </div>
            <div className="logout light">
                <button onClick={handleVoltar} className='button-link' to="/">Voltar</button>
            </div>
        </nav>
    </header>
      <div className="body-admin light">
        <MenuAdmin menu={menu} />
        <div className="container-admin container-admin-menu light container-dashboard">
        <div className="header">
                <h4>Dashboard</h4>
                <div className='actions'>
                <a
                  onClick={() => {setTurno(turno);setRefresh(true)}}
                  data-value={turno}  
                  className="btn btn-secondary"
                  href="#"
                >
                  <p>Atualizar</p>
                </a>

                </div>
            </div>
          <section id='dashboard' >
          <div className="content-form">
            <form className='light'>
              <label>
                <p>Selecione um período</p>
                <select
                  id="periodo"
                  onChange={(event) => setTurno(event.target.value)}
                  value={turno}
                >
                  <option value={turno?turno:''}>{turno?`${validConditions[turno].grandeArea} - ${validConditions[turno].turnoApresentação}`:'Selecione uma opção'}</option>
                  <option value="A">Artes e Humanidades - matutino</option>
                  <option value="B">Artes e Humanidades - vespertino</option>
                  <option value="C">Saúde e Vida - matutino</option>
                  <option value="D">Saúde e Vida - vespertino</option>
                  <option value="E">Exatas e Tecnológicas - matutino</option>
                </select>

              </label>
            </form>
          </div>
          {turno&&(
            <>
            <div className="search-input">
          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Buscar"
          />
        </div>
        {searchValue&& filteredData.length > 0 ? (
            <div className="boxes">
                {filteredData.map((e, index) => (
                <div className="box" key={e._id} onClick={(event) => handleBoxClick(event, e._id,e.idEvent)}>
                    <div className='box-content'>
                        <p>{e.content.poster?`${e.content.poster} - `:""}{e.content.titulo}</p>
                        {e.authors.map((item, index) => (
                          <span key={index}>
                            {item.type}: {item.name}<br />
                          </span>
                        ))}
                        
                        <div className='status'>
                        {statusMap[e.content.status] && (
                            <>
                            <div className={`icon-ball ${statusMap[e.content.status].className}`} />
                            <p>{statusMap[e.content.status].text}</p>
                            </>
                        )}
                        </div>
                        {Object.keys(error).map((key, index) => error[key].msg && error[key].id === e._id&&(
                        <div className="message mt-2" key={index}>
                            <div className="error-message border">
                                <p className="font-size-12">{error[key].msg}</p>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                ))}
            </div>
            ) : (
              
            <p>{searchValue?"Nenhum valor encontrado":""}</p>
        )}
          {subAreasArray[0] &&( 
            <div className='areas'>
            <h4 className='mt-3'>Áreas aguardando avaliação</h4> 
            <div className='status'>
              {subAreasArray.map(subAreaObj => (
                <div className='box' key={subAreaObj.subArea}>
                  <h4>{subAreaObj.subArea}</h4>
                  <p>{subAreaObj.total}</p>
                </div>
              ))}
            </div>
            </div>
          )}
         
          <div className='general pb-3'>
            <div className='col'>
            <h4 className='mt-3'>Pôsteres</h4> 
            <div className='status'>
              <div className='box'>
                <h4>Aguardando check-in</h4>
                <p>{countAguardandoCheckin}</p>
              </div>
              <div className='box'>
                <h4>Aguardando avaliação</h4>
                <p>{countAguardandoAvaliacao}</p>
              </div>
              <div className='box'>
                <h4>em Avaliação</h4>
                <p>{countEmAvaliacao}</p>
              </div>
              <div className='box'>
                <div>
                <h4>Avaliado: {countAvaliado}</h4>
                <h4>Menção Honrosa: {countMencaoHonrosa}</h4>
                <h4>Indicação ao Prêmio: {countIndicacaoPremio}</h4>
                </div>
              </div>
            </div>
            {/*** INICIO POSTERES ****/}
            <div className='vazio'>
            <label>
                  <p>Pôsteres vazios</p>
                  <select
                    id="vazio"
                  >
                  {
                    Array.from({ length: eventInfo?.settings?.totens }).map((_, index) => {
                      const numPoster = index + 1 + 100;
                      const poster = articles.filter((item) => item.content.poster === numPoster); // Corrigido o operador de igualdade aqui
                      const conflito = poster.length;
                      if (conflito === 0 ) {
                        return (
                          <option value={numPoster}>{numPoster}</option>
                        )
                      }
                      
        
                      ;
                    })
                  }
                  </select>

            </label>
            </div>
            {ArticlesMencaoHonrosa[0]&&(
              <>
                <p className='mt-2'>Pôsteres com Menções Honrosas</p>
                <div className='posters-list mencao'>
                {
                    ArticlesMencaoHonrosa.map((e, index) => {
                      const numPoster = e.content.poster;
                      return (
                        <div className= {`poster`}  key={index}>
                          <p>{numPoster}</p>
                        </div>
                      );
                    })
                  }
                </div>
              </>
            )}
            {ArticlesPremioDestaque[0]&&(
              <>
              <p className='mt-2'>Pôsteres Indicações ao Prêmio Destaque</p>
              <div className='posters-list premio'>
                {
                  ArticlesPremioDestaque.map((e, index) => {
                    const numPoster = e.content.poster;
                    return (
                      <div className= {`poster`}  key={index}>
                        <p>{numPoster}</p>
                      </div>
                    );
                  })
                }
              </div>
              </>
            )
            }
            <p className='mt-3'>Pôsteres</p>
            <div className='legenda posters'>
              <div className='item conflito'>
                <p>Conflito de numeração</p>
              </div>
              <div className='item aguardando'>
                <p>Aguardando avaliação</p>
              </div>
              <div className='item emAvaliacao'>
                <p>em Avaliação</p>
              </div>
            </div>
            <div className='posters mt-1'>
            {eventInfo?.settings?.totens !== undefined &&
              Array.from({ length: eventInfo?.settings?.totens }).map((_, index) => {
                const numPoster = index + 1 + 100;
                const poster = articles.filter(
                  (item) => item.content.poster === numPoster && item.content.status !== "Excluído" && item.content.status !== "Falta justificada"
                  ); // Corrigido o operador de igualdade aqui
                const status = poster[0]?.content?.status
                const conflito = poster.length;
                let displayNone = false;
                if (conflito === 0 || status === "Avaliado" || status === "Aguardando apresentação" ) {
                  displayNone = true
                }
                let estilo;
                
                
                if (status === "Aguardando avaliação") {
                  estilo = "aguardando"
                }
                if (status === "em Avaliação") {
                  estilo = "emAvaliacao"
                }
                if (conflito > 1) {
                  estilo = "conflito"
                }

                return (
                  <div className= {`poster ${estilo} ${displayNone?"display-none":""}`}  key={index}>
                    <p>{numPoster}</p>
                  </div>
                );
              })
            }

            </div>
            {/*** FIM POSTERES ****/}
            </div>
            <div className='col'>
              <h4 className='mt-3'>Balcões - Ensino Médio</h4> 
              <div className='status'>
                <div className='box'>
                  <h4>Aguardando check-in</h4>
                  <p>{countAguardandoCheckinEM}</p>
                </div>
                <div className='box'>
                  <h4>Aguardando avaliação</h4>
                  <p>{countAguardandoAvaliacaoEM}</p>
                </div>
                <div className='box'>
                  <h4>em Avaliação</h4>
                  <p>{countEmAvaliacaoEM}</p>
                </div>
                <div className='box'>
                <div>
                <h4>Avaliado: {countAvaliadoEM}</h4>
                <h4>Menção Honrosa: {countMencaoHonrosaEM}</h4>
                <h4>Indicação ao Prêmio: {countIndicacaoPremioEM}</h4>
                </div>
              </div>
              </div>
              {/*** INICIO BALCOES ****/}
              <div className='vazio'>
              <label>
                    <p>Balcões vazios</p>
                    <select
                      id="vazio"
                    >
                    {
                      Array.from({ length: eventInfo?.settings?.balcoes }).map((_, index) => {
                        const numPoster = index + 1 + 100;
                        const poster = articlesEM.filter((item) => item.content.poster === numPoster); // Corrigido o operador de igualdade aqui
                        const conflito = poster.length;
                        if (conflito === 0 ) {
                          return (
                            <option value={numPoster}>{numPoster}</option>
                          )
                        }
                        
          
                        ;
                      })
                    }
                    </select>

              </label>
              </div>
              {ArticlesMencaoHonrosaEM[0]&&(
                <>
                  <p className='mt-2'>Balcões com Menções Honrosas</p>
                  <div className='posters-list mencao'>
                  {
                      ArticlesMencaoHonrosaEM.map((e, index) => {
                        const numPoster = e.content.poster;
                        return (
                          <div className= {`poster`}  key={index}>
                            <p>{numPoster}</p>
                          </div>
                        );
                      })
                    }
                  </div>
                </>
              )}
              {ArticlesPremioDestaqueEM[0]&&(
                <>
                <p className='mt-2'>Balcões Indicações ao Prêmio Destaque</p>
                <div className='posters-list premio'>
                  {
                    ArticlesPremioDestaqueEM.map((e, index) => {
                      const numPoster = e.content.poster;
                      return (
                        <div className= {`poster`}  key={index}>
                          <p>{numPoster}</p>
                        </div>
                      );
                    })
                  }
                </div>
                </>
              )
              }
              <p className='mt-3'>Balcões</p>
              <div className='legenda posters'>
                <div className='item conflito'>
                  <p>Conflito de numeração</p>
                </div>
                <div className='item aguardando'>
                  <p>Aguardando avaliação</p>
                </div>
                <div className='item emAvaliacao'>
                  <p>em Avaliação</p>
                </div>
              </div>
              <div className='posters mt-1'>
              {eventInfo?.settings?.balcoes !== undefined &&
                Array.from({ length: eventInfo?.settings?.balcoes }).map((_, index) => {
                  const numPoster = index + 1 + 100;
                  const poster = articlesEM.filter((item) => item.content.poster === numPoster && item.content.status !== "Excluído" && item.content.status !== "Falta justificada"); // Corrigido o operador de igualdade aqui
                  const status = poster[0]?.content?.status
                  const conflito = poster.length;
                  let displayNone = false;
                  if (conflito === 0 || status === "Avaliado" || status === "Aguardando apresentação" ) {
                    displayNone = true
                  }
                  let estilo;
                  
                  
                  if (status === "Aguardando avaliação") {
                    estilo = "aguardando"
                  }
                  if (status === "em Avaliação") {
                    estilo = "emAvaliacao"
                  }
                  if (conflito > 1) {
                    estilo = "conflito"
                  }

                  return (
                    <div className= {`poster ${estilo} ${displayNone?"display-none":""}`}  key={index}>
                      <p>{numPoster}</p>
                    </div>
                  );
                })
              }

              </div>
              {/*** FIM BALCOES ****/}
            </div>
            
          </div>
          
          </>
            
          )}
            
          </section>
         
        </div>
      </div>
    </>
  );
};

export default Dashboard;
