import logoExpanded from 'svg/logo-extended.svg';
import logoExpanded_1 from 'svg/logo-extended_1.svg';
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG
import { Link } from "react-router-dom";

import lock from 'svg/lock.svg';
import at_sign from 'svg/at-sign.svg';
import { isLogged } from "helpers/AuthHandler";
import React, {useState} from 'react';
import useAPI from 'helpers/API';
import { doLogin } from 'helpers/AuthHandler';
import { stringify } from 'qs';
const Login = ()=>{
    const api = useAPI();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setDisabled(true);
        setError('');
        const json = await api.login(email, password); 
        

        if (json.error) {
            setError(json.error);
            setDisabled(false);
        }else{
            doLogin(json.token);
            window.location.href = '/profile'
        }
        setDisabled(false);
    }
    return(
        <>
<section className="container container-signin-signup ">
<div className="signin ">
    <div className="logo">
        <a href=""><img src={logoExpanded_1} alt=""/></a>
    </div>
    {
        Object.keys(error).map((key, index) => (
            error[key].msg && (
              <div className="message mt-2" key={index}>
                <div className="error-message border">
                  <p className='font-size-12'>{error[key].msg}</p>
                </div>
              </div>
            )
          ))
    }
    <form onSubmit={handleSubmit} className="mt-2" autocomplete="off">
        <label className="input-container">
            <div className="input-icon ">
                <InlineSVG src={at_sign} alt="" onload="SVGInject(this)"/>
            </div>
            <input 
            className={error.email ? 'error-input' : ''}
            type="email" 
            name="email" 
            placeholder="Digite seu email" 
            disabled={disabled}
            value={email}
            onChange={e=>setEmail(e.target.value)}
            />

        </label>
        <label className="input-container mt-3">
            <div className="input-icon ">
                <InlineSVG src={lock} alt="" onload="SVGInject(this)"/>
            </div>
            <input 
            className={error.password ? 'error-input' : ''}
            type="password" 
            name="password" 
            placeholder="Digite sua senha" 
            disabled={disabled}
            value={password}
            onChange={e=>setPassword(e.target.value)}
            
            />
        </label>
        <button disabled={disabled} className="btn btn-secondary w-100 mt-4">Entrar</button>
        
    </form>
    
</div>
</section>

        </>
    )
}

export default Login