import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import logoSmall from 'svg/logoIC.svg';
import { openModal } from 'helpers/Modal';
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG


const HeaderAdmin = () => {
  const api = useAPI();
  const { id } = useParams();
  //const [eventInfo, setEventInfo] = useState({});

  const handleVoltar = () => {
    window.location.href = '/profile';
  };
/***** 
  useEffect(() => {
    const getEventInfo = async (id) => {
      const json = await api.getEventById(id);
      setEventInfo(json);
    };
    getEventInfo(id);
  }, []);
*/
  return (
    <>
     
      <header id="headerAdmin" className="">
        <nav>
          <div className="logo logo-admin">
            <Link to="/" className='logo-img  logo-img-admin-block '>
              <InlineSVG src={logoSmall} alt="" />
            </Link>
            <div className='icon-menu'onClick={(e) => {
                  openModal(e, 'menu');
                }} >
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="title">
              <p>Sistema de Gerenciamento</p>
            </div>
          </div>
          

          <div className="logout">
            <button className="button-link" onClick={handleVoltar}>
              Voltar
            </button>
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderAdmin;
