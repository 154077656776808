import {closeModal, openModal} from 'helpers/Modal';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { upload } from '@testing-library/user-event/dist/upload';
const Modal_newPeople = ({cpf,cpfAluno='',peopleType = '', updateAuthors = '',setCpf,setCpfAluno = undefined,setPeopleType=undefined})=>{
    const api = useAPI();
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    
    const handleSubmitNewPeople = async (e)=>{
        console.log(peopleType)
        console.log(cpf)
        e.preventDefault();
        //setDisabled(true);
        setError('');
        let newCPF = cpf;
        if(peopleType === "orientador"){
          newCPF = cpf
        }
        if(peopleType === "aluno"){
          newCPF = cpfAluno
        }
        
        console.log(newCPF)
        const json = await api.newPeople(name,email, newCPF );
        if (json.error) {
            setError(json.error);
            setDisabled(false);
        }else{
          closeModal(e, 'newPeople');
          if (updateAuthors) {
            updateAuthors(name, peopleType, newCPF);
          }
          setCpf('');
          if (setCpfAluno) {
            setCpfAluno('');
          }
          
          if (setPeopleType) {
            setPeopleType('');
          }
          setName('');
          setEmail('')
        }
        setDisabled(false);
    }
    return(
<>
<div className="container-modal newPeople">
  <div className="modal">
    <div className="content">
      <h3>Dados do CPF</h3>
      <div className="content-form">
        <form id="newPeople" onSubmit={handleSubmitNewPeople} className="mt-4" autoComplete="off">
          {Object.keys(error).map((key, index) => (
            error[key].msg && (
              <div className="message mb-2" key={index}>
                <div className="error-message border">
                  <p className="font-size-12">{error[key].msg}</p>
                </div>
              </div>
            )
          ))}
        

        <div className='mt-3 w-100'>
            <label>
                <p>Nome completo</p>
                <input
                className={error.name ? 'error-input' : ''}
                type="text"
                placeholder='Digite o nome completo'
                value={name}
                onChange={e=>setName(e.target.value)}
                />
            </label>
        </div>
        <div className='mt-3 w-100'>
            <label>
                <p>Email</p>
                <input
                className={error.email ? 'error-input' : ''}
                type="email"
                placeholder='Digite o email'
                value={email}
                onChange={e=>setEmail(e.target.value)}
                />
            </label>
        </div>
        <div className='mt-3 w-100'>
            <label>
                <p>CPF</p>
                <input
                className={error.cpf ||error.cpfAluno ? 'error-input' : ''}
                type="text"
                placeholder='Digite o cpf'
                value={cpf?cpf:cpfAluno}
                readOnly
                />
            </label>
        </div>
        </form>
      </div>
      <div className="modal-actions mt-4">
        <button form="newPeople" disabled={disabled} className="btn btn-primary mr-2">
            <p>Cadastrar</p>
          </button>
          <a onClick={(e) => { 
            closeModal(e, 'newPeople'); setError(''); 
            setCpf('');
            if (setCpfAluno) {
              setCpfAluno('');
            }
            if (setPeopleType) {
              setPeopleType('');
            }
            setName('');
            setEmail('')
            }} className="btn btn-secondary" href="">
            <p>Fechar</p>
            </a>

      </div>
    </div>
  </div>
</div>
</>
)
};
export default Modal_newPeople;