import Cookies from "js-cookie";
import qs from 'qs';

const BASEAPI = 'https://sgcic-production.up.railway.app';
const apiFetchPost = async (endpoint,body) =>{
    
    if (!body.token) {
        let token = Cookies.get('token');
        if (token) {
            body.token = token;
        }
    }
    const res = await fetch(BASEAPI+endpoint,{
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(body)
    });
    let json = await res.json();

    if (json.notallowed) {
        window.location.href = '/signin';
        json = {error:'Página não autorizada'}
    }
    return json;
}
const apiFetchPut = async (endpoint,body) =>{
    
    if (!body.token) {
        let token = Cookies.get('token');
        if (token) {
            body.token = token;
        }
    }
    const res = await fetch(BASEAPI+endpoint,{
        method:'PUT',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(body)
    });
    let json = await res.json();

    if (json.notallowed) {
        window.location.href = '/signin';
        json = {error:'Página não autorizada'}
    }
    return json;
}

const apiFetchGet = async (endpoint, body = {}) => {
    if (!body.token) {
      let token = Cookies.get('token');
      if (token) {
        body.token = token;
      }
    }
    
    const queryString = qs.stringify(body);
    const url = `${BASEAPI + endpoint}?${queryString}`;
  
    const res = await fetch(url);
    let json = await res.json();
  
    if (json.notallowed) {
      window.location.href = '/signin';
      json = { error: 'Página não autorizada' };
    }
    
    return json;
  };
  
const API = {
    
    salvarNoConferencias: async (tokenConferencia, titulo, resumo, authors) => {
        try {
          // Valide os parâmetros, se necessário
          if (!tokenConferencia || !titulo || !resumo || !authors) {
            throw new Error('Parâmetros inválidos.');
          }
      
          // Realize a chamada à API
          const json = await apiFetchPost('/resumo/conferencias', { tokenConferencia, titulo, resumo, authors });
      
          // Mensagem de log para indicar que a chamada foi bem-sucedida
          console.log('Chamada de API para /resumo/conferencias realizada com sucesso, dados enviados para o servidor.');
      
          // Retorne o resultado, se necessário
          return json;
        } catch (error) {
          // Trate os erros de maneira adequada
          console.error('Erro ao fazer a chamada de API para /resumo/conferencias:', error);
      
          // Permite que o erro se propague, se necessário
          throw error;
        }
      },
    
    login: async (email, password) => {
        const json = await apiFetchPost(
            '/user/signin',
            {email, password}
        );
        return json;
    },
    loginWithCPF: async (cpf) => {
        const json = await apiFetchPost(
            '/user/signinWithCPF',
            {cpf}
        );
        return json;
    },
    newEvent: async (title, edition, start, end) => {
        const json = await apiFetchPost(
            '/profile/event',
            {title, edition, start, end}
        );
        return json;
    },
    newPeople: async (name,email, cpf, isAdmin = false) => {
        console.log(isAdmin)
        const json = await apiFetchPost(
            '/user/people',
            {name, email, cpf, isAdmin}
        );
        return json;
    },
    newArticle: async (idEvent,content,authors) => {
        console.log({idEvent,content,authors})
        const json = await apiFetchPost(
            '/article',
            {idEvent,content,authors}
        );
        return json;
    },
    updateArticle: async (id,content,authors) => {
        const json = await apiFetchPut(
            '/article',
            {id,content,authors}
        );
        return json;
    },
    updateEvent: async (id,content) => {
        const json = await apiFetchPut(
            '/admin/event',
            {id,content}
        );
        return json;
    },
    newArticles: async (content) => {
        try {
          await apiFetchPost('/articles', {content});
          console.log('Chamada de API para /articles realizada com sucesso, dados enviados para o servidor.');
        } catch (error) {
          console.error('Erro ao fazer a chamada de API para /articles:', error);
        }
      },
    
    getPeople: async (cpf) => {
        const json = await apiFetchGet(
            '/user/people',
            { cpf}
        );
        return json;
    },
    getPeopleByID: async (id) => {
        const json = await apiFetchGet(
            '/user/peopleById',
            {id}
        );
        return json;
    },
 
    getListPeople: async (cpf) => {
        const json = await apiFetchGet(
            '/user/userData'
        );
        return json;
    },
    getArticleByID: async (idArticle) => {
        const json = await apiFetchGet(
            '/article',
            {idArticle}
        );
        return json;
    },
    getListArticle: async (idEvent,st=null) => {
        const json = await apiFetchGet(
            '/articles',
            {idEvent,st}
        );
        return json;
    },
    getListArticleByCPFAvaliador: async (idEvent,st,cpf) => {
        console.log(idEvent)
        const json = await apiFetchGet(
            '/articlesByCPFAvaliador',
            {idEvent,st,cpf}
        );
        return json;
    },
    
    getListArticleByCPF: async (cpf) => {
        const json = await apiFetchGet(
            '/articles/user',
            {cpf}
        );
        return json;
    },
    getEvents: async (options) => {
        const json =  await apiFetchGet(
            '/profile/event',
        )
        json.sort((a, b) => {
            const dateA = Date.parse(a.creationDate);
            const dateB = Date.parse(b.creationDate);
            return dateB - dateA; // Ordenar em ordem decrescente
          });
        return json;
    },
    getAllEvents: async () => {
        const json =  await apiFetchGet(
            '/events',
        )
        json.sort((a, b) => {
            const dateA = Date.parse(a.creationDate);
            const dateB = Date.parse(b.creationDate);
            return dateB - dateA; // Ordenar em ordem decrescente
          });
        return json;
    },
    getEventById: async (id) => {
        const json =  await apiFetchGet(
            `/admin/event`,
            {id}
        )
        return json;
    },
    getMyProfile: async (options) => {
        const json =  await apiFetchGet(
            '/profile/myProfile'
        )
        
        return json;
    },
    editMyProfile: async (name, email, cpf, idInstitution, password, newPassword) => {
        const isAdmin = true;
        const requestData = {
          name,
          email,
          cpf,
          idInstitution,
          password,
          isAdmin
        };
      
        // Verifica se a nova senha foi fornecida e adiciona ao objeto de requisição
        if (newPassword) {
          requestData.newPassword = newPassword;
        }
      
        const json = await apiFetchPut('/profile/myProfile', requestData);
        return json;
      },
    getInstitutions: async () => {
        const json =  await apiFetchGet(
            '/institutions'
        )
        
        return json;
    },
    getArtigosAvaliadosPor: async (cpf) => {
        const json =  await apiFetchGet(
            '/artigosAvaliadosPor',
            {cpf}
        )
        
        return json;
    },

    

}


export default () => API;