import Modal_editMyProfile from 'pages/Modal/editMyProfile';
import Modal_article from 'pages/Admin/Modal_Article';
import Modal_viewArticle from 'pages/Admin/Modal_viewArticle';
import Modal_ArticleAvaliador from 'pages/Admin/Modal_ArticleAvaliador';

import { resetFormValues } from "helpers/resetForm";
import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG
import {closeModal, openModal, disableScroll, enableScroll} from 'helpers/Modal';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import logoSmall from 'svg/logoIC_light.svg';
import { doLogout } from "helpers/AuthHandler";
import { useParams } from 'react-router-dom';

//Link
import { Link } from "react-router-dom"; 
const PerfilAvaliador = ()=>{
const api = useAPI();
const { id } = useParams();
const { avaliadorCPF } = useParams();

const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [cpf, setCpf] = useState('');
const [events,setEvents] = useState([])
const [articles,setArticles] = useState([])
const [error, setError] = useState({});
const [searchValue, setSearchValue] = useState('');
const [formInfo, setFormInfo] = useState([]);
const [formData, setFormData] = useState({});
const [refresh, setRefresh] = useState(false);
const [eventInfo, setEventInfo] = useState({});
const [data, setData] = useState([]);
const [selectedArticle, setSelectedArticle] = useState(null); // Estado para armazenar o item selecionado
const [eventosPermitindoSubmissao,seteventosPermitindoSubmissao] =useState([])
const [areas, setAreas] = useState([]);
const [dadosAvaliadorCarregados, setDadosAvaliadorCarregados] = useState(false);
const [dadosResumosCarregados, setDadosResumosCarregados] = useState(false);
const [dadosCarregados, setDadosCarregados] = useState(false);
const [botaoClicado, setBotaoClicado] = useState(false);

useEffect(() => {
    const fetchData = async () => {
        try {
            //Dados do Perfil do usuário
            setDadosAvaliadorCarregados(false);

            const profileJson = await api.getMyProfile();
            setName(profileJson.name);
            setEmail(profileJson.email);
            setCpf(profileJson.cpf);
            setDadosAvaliadorCarregados(true);
        } catch (error) {
            // Lidar com erros, se necessário
        }
    };
    fetchData();
}, []);  
useEffect(() => {
    const fetchData = async () => {
        setRefresh(false)
        try {
            
            setDadosResumosCarregados(false);
            //Artigos do usuário
            const articleEmAvaliacao = await getArticlesEmAvaliacao(id,avaliadorCPF)
            console.log(`EM AVALIAÇÃO`)
            console.log(articleEmAvaliacao.length)
            if(articleEmAvaliacao[0]){
                //const base =  incluirInfoAutoresEevento(articleEmAvaliacao)
                setData(articleEmAvaliacao)
                setDadosResumosCarregados(true);
            }else{
                const articlesAguardandoAvaliacao = await getArticlesAguardandoAvaliacao(id,avaliadorCPF)
                //setArticles(articlesJson);
                setData(articlesAguardandoAvaliacao)
                //const base =  incluirInfoAutoresEevento(articlesAguardandoAvaliacao)
                
                const subAreas = [];
                articlesAguardandoAvaliacao.forEach((e) => {
                    const jaExiste = subAreas.includes(e.content.subArea);
                    if (!jaExiste) {
                        subAreas.push(e.content.subArea);
                        subAreas.sort(); // Ordena o array em ordem alfabética
                    }
                });
                setAreas(subAreas)
                setDadosResumosCarregados(true);
            }

        } catch (error) {
            // Lidar com erros, se necessário
        }
    };
    fetchData();
}, [refresh,]);
useEffect(() => {
    if (dadosResumosCarregados && dadosResumosCarregados) {
        setDadosCarregados(true)
    } 
    console.log("DADOS DO RESUMO")
    console.log(dadosResumosCarregados)
    console.log("DADOS DO AVALIADOR")
    console.log(dadosResumosCarregados)
}, [dadosResumosCarregados]);

//Altera o estilo de dark para light
useEffect(() => {
    // Verifica a presença da classe .light em elementos filhos
    const hasLightClassInDOM = document.querySelector('.light');

    // Define o estilo do background do body com base no resultado
    document.body.style.background = hasLightClassInDOM ? '#F7F7F7' : '#000';
}, []);  
//Inclui informação dos autores e do evento em cada item 
const incluirInfoAutoresEevento = async (bd) => {
    try {
        const newData = await Promise.all(
        bd.map(async (item) => {
            const autoresComInfo = await Promise.all(
            item.authors.map(async (autor) => {
                const dataUser = await api.getPeople(autor.cpf);
                if (dataUser) {
                return { ...autor, name: dataUser.name };
                }
                return autor;
            })
            );
            const dataEvent = await api.getEventById(item.idEvent)
            return { ...item, authors: autoresComInfo,dataEvent };
        })
        );
        setData(newData)
        return newData;
    } catch (error) {
        console.error("Erro ao incluir informações dos autores:", error);
        return bd;
    }
};
const getArticlesAguardandoAvaliacao = async (id, cpf) => {
    try {
        const st = 'Aguardando avaliação'
        const articlesJson = await api.getListArticle(id,st);
        const articlesAguardandoAvaliacao = articlesJson.filter((item) => {
            const authorCpfs = item.authors.map((e) => e.cpf);
            return item.content.status === 'Aguardando avaliação' && !authorCpfs.includes(cpf);
        });
        return articlesAguardandoAvaliacao;
    } catch (error) {
        console.error('Erro ao obter artigos:', error);
        return [];
    }
}
const getArticlesEmAvaliacao = async (id, cpf) => {
    try {
        const st = 'em Avaliação'
        const articlesJson = await api.getListArticleByCPFAvaliador(id,st,cpf);
            const articlesEmAvaliacao = articlesJson.filter((item) => {
                return item.content.status === 'em Avaliação' && item.content.avaliador === cpf;
            });
        
        return articlesEmAvaliacao;
    } catch (error) {
        console.error('Erro ao obter artigos:', error);
        return [];
    }
}

//Altera as variáveis formInfo e eventInfo
const getEventInfo = async (id) => {
    try {
        
        //pega os dados do evento no BD
        const json = await api.getEventById(id);
        //pega o formulário padrão
        const arrayForm = json.settings.formularios.formulario_artigo;
        //pega as regras para alterar o formulário padrão
        const rules = json.settings.formularios.formulario_avaliador;
        //altera o formulário padrão de acordo com as regras
        const arrayWithRules = arrayForm.map(item => {
            const rule = rules.find(ruleItem => ruleItem.labelId === item.labelId);
            if (rule) {
                return { ...item, ...rule };
            }
            return item;
        });
        setFormInfo(arrayWithRules); // Espera até que setFormInfo seja concluído
        setEventInfo(json);
        return json; // Retorna os dados processados
    } catch (error) {
        setFormInfo([{}])
        console.error("Error fetching event info:", error);
        throw error; // Lança o erro para ser tratado no bloco then ou catch
    }
}
//Aciona o getEventInfo e se der certo, 
const handleBoxClick = async (event, id, idEvent) => {
   setBotaoClicado(true)
getEventInfo(idEvent)
.then(async(json) => {
    //Avaliador já tem um trabalho em avalia
    const articleEmAvaliacao = await getArticlesEmAvaliacao(idEvent,avaliadorCPF)
    console.log("articleEmAvaliacao")
    

    getArticlesAguardandoAvaliacao(idEvent,cpf)
    const bd = await api.getArticleByID(id)
    console.log(bd)
    if (bd.content.status !== "Aguardando avaliação" && bd.content.avaliador !== cpf) {
        setError({error:{id,msg:'Ops! Esse trabalho já está sendo avaliado. Escolha outro!'}})
        return
    }
    
    bd.content.status = 'em Avaliação'
    bd.content.avaliador = cpf
    await api.updateArticle(bd._id,bd.content,bd.authors);
    const selected = await bd;
    setFormData(selected)
    setSelectedArticle(selected);
    //abre o modal
    openModal(event, 'articleAvaliador');
    setRefresh(true)
    setTimeout(() => {
        // Esta linha será executada após 2 segundos
        setBotaoClicado(false);
      }, 2000);
})
.catch(error => {
    // Lida com erros na obtenção das informações do evento
    console.error(error);
});
};    
    
    
const handleLogout = ()=>{
    doLogout();
    window.location.href='/avaliador';
}

const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
};
// Filtra a lista de resumos com base no valor do input de busca
let filteredData =[]
if(data.length>0){
filteredData = data.filter((item) => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const lowerCaseTitulo = item?.content?.titulo?.toLowerCase() || '';
    const lowerCaseEdital = item?.content?.edital?.toLowerCase() || '';
    const lowerCaseInstituicao = item?.content?.instituicao?.toLowerCase() || '';
    const lowerCaseId = item?._id?.toLowerCase() || '';
    const lowerCaseArea = item?.content?.subArea.toLowerCase() || '';


    // Função para verificar se o CPF ou email de algum autor inclui o valor de busca
    const authorContainsSearchValue = item.authors?.some((author) => {
    const lowerCaseCpf = author?.cpf?.toLowerCase() || '';
    const lowerCaseEmail = author?.email?.toLowerCase() || '';
    return lowerCaseCpf.includes(lowerCaseSearchValue) || lowerCaseEmail.includes(lowerCaseSearchValue);
    });

    return (
    lowerCaseTitulo.includes(lowerCaseSearchValue) ||
    lowerCaseInstituicao.includes(lowerCaseSearchValue) ||
    lowerCaseEdital.includes(lowerCaseSearchValue) ||
    lowerCaseArea.includes(lowerCaseSearchValue) ||

    lowerCaseId.includes(lowerCaseSearchValue) ||
    authorContainsSearchValue
    );
});
}
      
const statusMap = {
"Pendente": { className: "icon-ball-red", text: "Pendente" },
"Aguardando apresentação": { className: "icon-ball-white", text: "Aguardando apresentação" },
"Aguardando avaliação": { className: "icon-ball-orange", text: "Aguardando avaliação" },
"em Avaliação": { className: "icon-ball-yellow", text: "Em Avaliação" },
"Avaliado": { className: "icon-ball-green", text: "Avaliado" }
};
return(
<>

<header id="headerAdmin" className="header-profile">
    <nav>
        <div className="logo light"> 
            <Link to="/" className='logo-img logo-img-block'>
                <InlineSVG src={logoSmall} alt="" />
            </Link>
            <div className="title">
                <p>Iniciação Científica</p>
            </div>
        </div>
        <div className="logout light">
            <button onClick={handleLogout} className='button-link' to="/avaliador">Sair</button>
        </div>
    </nav>
</header>
<div className='light-form'>
    
    <Modal_editMyProfile/>
    <Modal_article 
        formInfo={formInfo} 
        formData={formData} 
        setFormInfo={setFormInfo} 
        setFormData={setFormData} 
        setRefresh={setRefresh} 
        refresh={refresh} 
        setSearchValue = {setSearchValue} 
        getEventInfo = {getEventInfo}
        eventosPermitindoSubmissao = {eventosPermitindoSubmissao}
        cpfUser = {cpf}
    />
    <Modal_ArticleAvaliador 
        formInfo={formInfo} 
        formData={formData} 
        setFormInfo={setFormInfo} 
        setFormData={setFormData} 
        setRefresh={setRefresh} 
        refresh={refresh} 
        setSearchValue = {setSearchValue} 
        getEventInfo = {getEventInfo}
        eventosPermitindoSubmissao = {eventosPermitindoSubmissao}
        cpfUser = {cpf}
        setDadosResumosCarregados = {setDadosResumosCarregados}
    />
    
    <Modal_viewArticle 
        article={selectedArticle}
        cpf={cpf}
        setRefresh={setRefresh} 
    />
    
</div>
<div className="light body-admin ">
    <div className="container-admin container-profile avaliador">
        {dadosCarregados  &&
        <section id="form-submissao">
            {!botaoClicado && (
                <>
                <div className="header">
                <h4>Aguardando avaliação ({filteredData > 0 ?filteredData.length: filteredData.length })</h4>
                {true&&
                    <div className='actions'>
                    <a onClick={(e) => openModal(e, 'editMyProfile')}  className="btn btn-secondary" href="">
                        <p>Meus dados</p>
                    </a>
                </div>
                }
                
            </div>
            <div className="search-input light-form">
                
                <label>
                  
                  <select className='mt-2'
                    value={searchValue}
                    onChange={handleSearchChange}
                  
                    
                  >
                    <option value=''>
                      Filtre por área
                    </option>
                    {areas?.map((element, index) => (
                      <option key={index} value={element}>
                        {element}
                      </option>
                    ))}
                  </select>
              </label>
              
            </div>
                </>

            )

            }
            
            {!botaoClicado && filteredData.length > 0 ? (
            <div className="boxes">
                {filteredData.map((e, index) => (
                <div className="box" key={e._id} onClick={(event) => handleBoxClick(event, e._id,e.idEvent)}>
                    <div className='box-content'>
                        <p className='bold'>{e.content.subArea}</p> 
                        <p>{e.content.titulo}</p>

                        <div className='status'>
                        {Object.keys(error).map((key, index) => (
                            error[key].msg && error[key].id === e._id&& (
                                <div className="message mt-2" key={index}>
                                <div className="error-message border">
                                    <p className='font-size-12'>{error[key].msg}</p>
                                </div>
                                </div>
                            )
                            ))}
                                                   
                        </div>
                        
                        
                        
                    </div>
                </div>
                ))}
            </div>
            ) : (!botaoClicado&&
            <p>Nenhum resumo encontrado.</p>
            )}
        </section>
        }
        {!dadosCarregados  &&  
        <section>
            <h4>Carregando...</h4>
        </section>
        }
        {botaoClicado  &&  
        <section>
            <h4>Carregando Ficha de Avaliação. Ao carregar, dirija-se ao pôster!</h4>
        </section>
        }
    </div>
    

</div>
</>
)
};

export default PerfilAvaliador;