import React, { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import { closeModal, openModal } from 'helpers/Modal';
import cpfValidator from 'helpers/cpfValidator';
import emailValidator from 'helpers/emailValidator';

import * as XLSX from 'xlsx';

const Modal_upload = () => {
  const api = useAPI();
  const { id } = useParams();
  const [error, setError] = useState({});
  const [eventInfo, setEventInfo] = useState({});
  const [headTableDefault, setHeadTableDefault] = useState([]);
  const [headTable, setHeadTable] = useState([]);
  const [headTableFinal, setHeadTableFinal] = useState([]);
  const [bodyTable, setBodyTable] = useState([]);
  const [selectedTHead, setSelectedTHead] = useState("");
  const [selectedTHeadDefault, setSelectedTHeadDefault] = useState("");
  const [load, setLoad] = useState("");
  const [finalizado, setFinalizado] = useState("");
  const [data, setData] = useState([]);


  
  // Função para ler o conteúdo do arquivo Excel
  const handleExcelUpload = (file) => {
    setHeadTableDefault(["titulo",  "edital", "nameAluno", "cpfAluno", "emailAluno", "nameOrientador", "cpfOrientador", "emailOrientador"]);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setBodyTable(jsonData.slice(1));
      setHeadTable(jsonData[0]);
      setHeadTableFinal(jsonData[0]);
    };
    reader.readAsBinaryString(file);
  };
  const handleVincular = () => {
    if (selectedTHead && selectedTHeadDefault) {
      // Cria uma cópia da headTable atual
      const updatedHeadTable = [...headTable];
      const updatedHeadTableFinal = [...headTableFinal];

      // Encontra o índice do item selecionado em headTable e do item selecionado em headTableDefault
      const selectedTHeadIndex = updatedHeadTable.indexOf(selectedTHead);
      const selectedTHeadDefaultIndex = headTableDefault.indexOf(selectedTHeadDefault);
      const selectedTHeadDefaultFinalIndex = headTableFinal.indexOf(selectedTHead);
      if (selectedTHeadIndex !== -1 && selectedTHeadDefaultIndex !== -1) {
        // Substitui o item em headTable pelo item em headTableDefault
        updatedHeadTable[selectedTHeadIndex] = selectedTHeadDefault;
        updatedHeadTableFinal[selectedTHeadDefaultFinalIndex] = selectedTHeadDefault;

        // Remove o item de headTableDefault usando pop (removendo pelo índice encontrado)
        const updatedHeadTableDefault = [...headTableDefault];
        updatedHeadTableDefault.splice(selectedTHeadDefaultIndex, 1);
  
        // Remove o item de headTable usando pop (removendo pelo índice encontrado)
        updatedHeadTable.splice(selectedTHeadIndex, 1);
  
        // Atualiza os estados
        setHeadTable(updatedHeadTable);
        setHeadTableDefault(updatedHeadTableDefault);
        setHeadTableFinal(updatedHeadTableFinal);
        setSelectedTHead("");
        setSelectedTHeadDefault("");
      }
    }
  };
  
  const padLeftZeros = (value, length) => {
    const strValue = value ? String(value) : '';
const count = Math.max(length - strValue.length, 0);
return '0'.repeat(count) + strValue;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const jsonFinal = bodyTable.map((e) => {
      const json = {};
      e.forEach((item, i) => {
        json[headTableFinal[i]] = item;
        json.idEvent = id;
      });
      return json;
    });
  
    const bd = jsonFinal.map((e) => {
      setLoad(true)
      const titulo = e.titulo && e.titulo.trim().replace(/\r?\n/g, '');
      const instituicao = 'UNB';
      const edital = e.edital && e.edital.trim().replace(/\r?\n/g, '');
      const nameOrientador = e.nameOrientador && e.nameOrientador.trim().replace(/\r?\n/g, '');
      const nameAluno = e.nameAluno && e.nameAluno.trim().replace(/\r?\n/g, '');
      const emailAluno = e.emailAluno && e.emailAluno.trim().replace(/\s+/g, '');
      const emailOrientador = e.emailOrientador && e.emailOrientador.trim().replace(/\s+/g, '');
      const cpfAluno = padLeftZeros(`${e.cpfAluno}`, 11).replace(/\D|\r?|\n/g, '');
      const cpfOrientador = padLeftZeros(`${e.cpfOrientador}`, 11).replace(/\D|\r?|\n/g, '');
      const status = "Pendente"
      return {
        dataContent: {
          idEvent: id,
          content: {titulo,instituicao,edital,status},
          authors: [
            {
              type: 'orientador',
              cpf: cpfOrientador,
            },
            {
              type: 'aluno',
              cpf: cpfAluno,
            },
          ],
        },
        dataUsers: [
          { name: nameAluno, email: emailAluno, cpf: cpfAluno },
          { name: nameOrientador, email: emailOrientador, cpf: cpfOrientador},
        ],
      };
    });
    
  
    
    const processarBd1 = async (bd) => {
      try {
        const erros = [];
        for (const e of bd) {
          for (const element of e.dataUsers) {
            if (!cpfValidator(element.cpf)) {
              erros.push(`CPF (${element.cpf}) do(a) ${element.name} não é válido.`);
            }else if (!emailValidator(element.email) ) {
              erros.push(`Email (${element.email}) do(a) ${element.name} não é válido.`);
            }
          }
        }
    
        if (erros.length > 0) {
          const errorMessage = erros.join(' ');
          setError({ error: { msg: errorMessage } });
        }else{
          console.log(bd)
          const listaDeCPF = [];
          bd.forEach((e) => {
            e.dataUsers.forEach((item) => {
              const { cpf } = item;
              // Verifica se o CPF já existe em algum dos objetos do array listaDeCPF
              const cpfExists = listaDeCPF.some((obj) => obj.cpf === cpf);
              // Se o CPF não existir no array, adiciona o item em listaDeCPF
              if (!cpfExists) {
                listaDeCPF.push(item);
              }
            });
          });
          console.log('Lista de CPFs')
          const listaDeUsuarios = await api.getListPeople();
          console.log(listaDeUsuarios)
          // Filtra os objetos presentes em listaDeCPF que não estão em listaDeUsuarios
          const listaDeCPFExclusiva = listaDeCPF.filter((itemCPF) => {
            return !listaDeUsuarios.some((itemUsuario) => itemUsuario.cpf === itemCPF.cpf);
          });
          
          for (let i = 0; i < listaDeCPFExclusiva.length; i++) {
            const e = listaDeCPFExclusiva[i];
            await api.newPeople(e.name, e.email, e.cpf);
          }
          const insertBd = bd.map((e) => e.dataContent);
          console.log('inserBD =>');
          console.log(insertBd);

          // Divide o array insertBd em partes menores (cada parte com no máximo 50 elementos)
          const chunkSize = 50;
          for (let i = 0; i < insertBd.length; i += chunkSize) {
            const chunk = insertBd.slice(i, i + chunkSize);
            await api.newArticles(chunk);
          }

        }
      } catch (erro) {
        console.error("Ocorreu um erro:", erro);
      }


    };
    
// Chame a função para processar o banco de dados
processarBd1(bd)
.then(() => {
  console.log(bd)
  setLoad(false)
  setFinalizado(true);
  console.log("Processamento concluído com sucesso!");

})
.catch((erro) => {
  setLoad(false)
  setError({ error: { msg: "Ocorreu um erro durante o processamento" } });
  console.error("Ocorreu um erro durante o processamento:", erro);
});


    
  };
  
  

  return (
    <>
      <div className="container-modal upload">
        <div className="modal">
          <div className="content">
            <h3>Upload de Resumos da UnB</h3>
            <div className="content-form">
              {/* Campo de upload de arquivo Excel */}
              <input
                type="file"
                accept=".xls, .xlsx"
                onChange={(e) => {handleExcelUpload(e.target.files[0]);setError({})}}
              />
              {Object.keys(error).map((key, index) =>
                  error[key].msg ? (
                    <div className="message mb-2" key={index}>
                      <div className="error-message border">
                        <p className="font-size-12">{error[key].msg}</p>
                      </div>
                    </div>
                  ) : null
                )}
              {
                bodyTable[0]  && (
                  <>
                  
                  <div className='table'>
                    <table border="0">
                      <thead>
                        <tr>
                          {headTableFinal.map((item) => (
                            <th key={item}>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {bodyTable.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                              <td key={`${rowIndex}-${cellIndex}`}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='form'>
                    
                    {headTableDefault[0]&&(
                      <>
                      <form>
                      <div className='mt-3'>
                        <label><p>Selecione um item:</p>
                        <select id="tHeadDefault" name="tHeadDefault" required value={selectedTHeadDefault} onChange={(e) => setSelectedTHeadDefault(e.target.value)}>
                          <option value="" >Selecione uma opção</option>
                          {headTableDefault.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
  
                        </label>
                      </div>
                      {selectedTHeadDefault &&(
                        <>
                        <div className='mt-3'>
                          <label><p>O item {selectedTHeadDefault} corresponde a qual coluna? </p>
                          <select id="tHead" name="tHead" required onChange={(e) => {setSelectedTHead(e.target.value)}}>
                              <option value="" >Selecione uma opção</option>
                              {headTable.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                          </label>
                        </div>
                        </>
                      )

                      }
                      
                      {selectedTHead && !finalizado && !load &&(
                        <>
                        <div className='mt-3'>
                          <button type="button" className="btn btn-primary w-100" onClick={handleVincular}><p>Vincular</p></button>
                        </div>
                        </>
                      )

                      }
                      
                      </form>
                      </>
                    )}
                    
                  </div>
                  </>
                )
              }
              
            </div>

            <div className="modal-actions mt-4">
              {load &&(
                <>
                <div className="message mb-2" >
                  <div className="error-message border">
                    <p className="font-size-12">Aguarde o processamento...</p>
                  </div>
                </div>
                </>
              )

              }
              {!headTableDefault[0] && bodyTable[0] && !finalizado && !load && (
                <button
                form="upload"
                className="btn btn-primary mr-2"
                onClick={handleSubmit}
                ><p>Enviar</p></button>
              )}
              {!load &&(
                <>
                  <button
                  onClick={(e) => {
                    closeModal(e, 'upload');
                    setHeadTable(["titulo", "instituicao", "edital", "nameAluno", "cpfAluno", "emailAluno", "nameOrientador", "cpfOrientador", "emailOrientador"]);
                    setBodyTable([]);
                    setSelectedTHeadDefault("");
                    setSelectedTHead("");
                    setError({});
                    window.location.reload();
                  }}
                  className="btn btn-secondary"
                >
                  <p>Fechar</p>
                </button>
                </>
              )

              }
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal_upload;
