import React, { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import InlineSVG from 'react-inlinesvg';
import { useParams } from 'react-router-dom';
import logo from 'svg/cic_completa.svg';

const ListaResumos = () => {
  const api = useAPI();
  const { id } = useParams(); // Substitua "parametro" pelo nome real do parâmetro que você está usando

  const [dados, setDados] = useState([]);
  const [event, setEvent] = useState([]);
  const [filteredGrandeArea, setFilteredGrandeArea] = useState(null);

  useEffect(() => {
    // Lógica para carregar dados usando o hook useAPI ou outra lógica desejada
    const fetchData = async () => {
      try {
        const responseData = await api.getListArticle(id);
        // Filtra os itens onde data.content.introdução não é "" ou null ou undefined
        const filteredData = responseData.filter(item => 
          item.content?.introdução !== "" &&
          item.content?.introdução !== null &&
          item.content?.introdução !== undefined
        );
        setDados(filteredData);
        // Verifica se há algum item na lista antes de chamar api.getEventById
        if (filteredData.length > 0) {
          const event = await api.getEventById(filteredData[0].idEvent);
          setEvent(event);
        }
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      }
    };
  
    fetchData();
  }, [api, id]);
  
  const handleFilterChange = (e) => {
    setFilteredGrandeArea(e.target.value);
  };
  const qrCodeStyle = {
    background: '#EDF2F2',
    width: '50px',
    height: '50px',
  };

  return (
    <>
      <div className="resumosHtml-body">
        <div className="body">
          {/* Dropdown para filtrar por grandeArea */}
          <p className='no-print'>Filtre por Grande Área</p>
          <select className='no-print' id="grandeAreaFilter" onChange={handleFilterChange}>
            <option value="">Todas as Áreas</option>
            <option value="Artes e Humanidades">Artes e Humanidades</option>
            <option value="Saúde e Vida">Saúde e Vida</option>
            <option value="Exatas e Tecnológicas">Exatas e Tecnológicas</option>
          </select>

          {/* Renderizar os dados conforme necessário */}
           {/* Renderize os dados conforme necessário */}
           {dados.filter((item) => item.content?.grandeArea === filteredGrandeArea)
            .map((item) =>(
              <>
              <div className="header">
                <InlineSVG src={logo} alt="" />
                  <div className="title">
                    <h4>
                      CONGRESSO {event.edition}<br />
                      INICIAÇÃO CIENTÍFICA<br/>
                      <span>{filteredGrandeArea}</span>
                    </h4>
                    <p dangerouslySetInnerHTML={{ __html: event.title?.replace('Iniciação Científica','IC').replace(/ e /g, '<br/>').replace('Iniciação Científica','IC') }}></p>

                   
                  </div>
                </div>

                <div className='resumos'>
                    <div key={item.id}>
                    <a href={item.content?.linkConferencia}>
                      <h4>{item.content.titulo}</h4>
                    </a>
                    <br></br>
                      <span>{item.authors?.map(autor => `${autor?.name} (${autor?.type})`).join(' e ')}</span>
                      <br></br>
                      <span>{item.content?.grandeArea} - {item.content?.subArea} - {item.content?.edital}</span> 
                      <br></br>
                      <br></br>
                      <span>Palavras-chaves{item.content?.palavras_chaves}</span> 
                      <br></br>
                      <br></br>
                      <p>{item.content?.introdução}</p><br></br>
                      <p>{item.content?.metodologia}</p><br></br>
                      <p>{item.content?.conclusao}</p><br></br>
                      <p>{item.content?.resultado}</p>
                    </div>
                    <div className="page-break"></div>
                </div>
              </>
              ))}
          
          
        </div>
      </div>
    </>
  );
};

export default ListaResumos;
