import { connect } from "react-redux";
import React, { useState, useEffect } from 'react';
import Paths from 'paths';

function App(props) {
  const [pageTitle, setPageTitle] = useState('IC DF');
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
  const handleTitleChange = (event) => {
    setPageTitle(event.target.value);
  };
  return (
    <>
      
      <Paths></Paths>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
