import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import { openModal } from 'helpers/Modal';
import Modal_upload from 'pages/Admin/Modal_upload';
import Modal_article from 'pages/Admin/Modal_Article';
import Modal_menu from 'pages/Admin/Modal_menu';
import HeaderAdmin from 'pages/Admin/HeaderAdmin';
import MenuAdmin from 'pages/Admin/MenuAdmin';
import { resetFormValues } from "helpers/resetForm";
import menuAdmin from 'helpers/menuAdmin';
import InputMask from 'react-input-mask';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';
import Modal_newOwner from 'pages/Admin/Modal_newOwner';

const Configuracoes = () => {
  const api = useAPI();
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({});
  const [eventInfo, setEventInfo] = useState({});
  const [menu,setMenu]=useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cpfValid, setCpfValid] = useState(false);
  const [owners, setOwners] = useState([]);
  const [grandeArea, setGrandeArea] = useState('');
  const [turno, setTurno] = useState('');
  const [formularioIndex, setFormularioIndex] = useState(null)
  const [totens, setTotens] = useState(null);
  const [balcoes, setBalcoes] = useState(null);
  const [dia, setDia] = useState(null);

useEffect(() => {
    const fetchData = async () => {
        try {
            setMenu(menuAdmin(2,id))
            const json = await api.getEventById(id);
            setEventInfo(json);
            setFormularioIndex(json.settings.formularios.formulario_artigo.findIndex(
              (label) => label.labelId === "turnoApresentação"
            ))
            const ownersDetails = await Promise.all(json.owners.map(async (e) => {
              const user = await api.getPeopleByID(e);
              return user.name;
            }));
            
            setOwners(ownersDetails);
        } catch (error) {
            // Lidar com erros, se necessário
        }
    };
    fetchData();
}, [setEventInfo]);


const incluirTurno = async (e) => {
  e.preventDefault();

  try {
    const newObj = { title: turno, linkID: grandeArea,dia };

    // Find the index of the "turnoApresentação" label in the formularios array
    setFormularioIndex(eventInfo.settings.formularios.formulario_artigo.findIndex(
      (label) => label.labelId === "turnoApresentação"
    ))
    

    if (formularioIndex !== -1) {
      // Create a deep copy of the eventInfo object

      // Add newObj to the options array of the "turnoApresentação" label
      eventInfo.settings.formularios.formulario_artigo[
        formularioIndex
      ].options.push(newObj);


      // Simulate successful submission
      await api.updateEvent(id, eventInfo);
      setGrandeArea('');
      setTurno('');
      setDia('')
      setShowSuccessMessage(true);
    }
  } catch (error) {
    // Handle errors, if necessary
  }
};
const handleDeleteTurno = async (index) => {
  try {
    // Make a deep copy of eventInfo to avoid directly modifying the state
    const updatedEventInfo = { ...eventInfo };

    // Find the index of the "turnoApresentação" label in the formularios array
    const formularioIndex = updatedEventInfo.settings.formularios.formulario_artigo.findIndex(
      (label) => label.labelId === "turnoApresentação"
    );

    if (formularioIndex !== -1) {
      // Remove the option at the specified index
      updatedEventInfo.settings.formularios.formulario_artigo[formularioIndex].options.splice(
        index,
        1
      );

      // Simulate successful submission
      await api.updateEvent(id, updatedEventInfo);

      // Update the state with the updated eventInfo
      setEventInfo(updatedEventInfo);

      setShowSuccessMessage(true);
    }
  } catch (error) {
    // Handle errors, if necessary
  }
};


const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    // Simulação de envio bem-sucedido
    await api.updateEvent(id, eventInfo);
    
    setShowSuccessMessage(true);
  } catch (error) {
    // Lida com erros, se necessário
  }
};

useEffect(() => {
  // Após 4 segundos, redefina o estado showSuccessMessage para false
  if (showSuccessMessage) {
    const timeoutId = setTimeout(() => {
      setShowSuccessMessage(false);
    }, 4000); // 4 segundos

    return () => clearTimeout(timeoutId); // Limpar o timeout se o componente for desmontado
  }
}, [showSuccessMessage]);

const handleCpfValidation = (value) => {
  setCpf(value);
  setCpfValid(cpfValidator(value));
};

const handleOwner = async (e) => {
  setError({});
  const json = await api.getPeople(cpf);
  if (json.error) {
    openModal(e, 'newOwner');
    setCpf(cpf);
  } else {
    updateOwners(json.id);
    setCpf('');

  }
};
const updateOwners = async (id) => {
  setEventInfo((prevData) => {
    const updatedOwners = prevData.owners
      ? [...prevData.owners, id]
      : [id];

    const ownersDetailsPromise = updatedOwners.map(async (e) => {
      const user = await api.getPeopleByID(e);
      return user.name;
    });

    Promise.all(ownersDetailsPromise).then((ownersDetails) => {
      setOwners(ownersDetails);
    });

    return {
      ...prevData,
      owners: updatedOwners,
    };
  });
};

const handleDelete = async (index) => {
  setEventInfo((prevData) => {
    const updated = [...prevData.owners];
    updated.splice(index, 1);
    const updatedData = {
      ...prevData,
      owners: updated,
    };
    updateOwnersDetails(updatedData.owners); // Atualiza os detalhes dos proprietários
    return updatedData;
  });
};

const updateOwnersDetails = async (ownerIds) => {
  const ownersDetailsPromise = ownerIds.map(async (e) => {
    const user = await api.getPeopleByID(e);
    return user.name;
  });

  Promise.all(ownersDetailsPromise).then((ownersDetails) => {
    setOwners(ownersDetails);
  });
};



 
return (
<>
<Modal_newOwner
    cpf={cpf}
    updateOwners={updateOwners}
    setCpf={setCpf}
    id={id}
  />
<Modal_menu eventInfo={eventInfo} menu={menu} />
<HeaderAdmin />
  <div className="body-admin">
    <MenuAdmin menu={menu}/>
    
    <div className="container-admin container-admin-menu">
      <h3 className='mb-3'>Configurações</h3>
      {showSuccessMessage && (
      <div className="message success ">
            <div className="success-message">
              <p className="font-size-12">Evento editado com sucesso!</p>
            </div>
      </div>
      )}
      <section id="form-submissao">
        
          
        
        <form id="myForm" onSubmit={handleSubmit} className="mt-4" autocomplete="off">
          {
          Object.keys(error).map((key, index) => (
              error[key].msg && (
              <div className="message mb-2" key={index}>
                  <div className="error-message border">
                  <p className='font-size-12'>{error[key].msg}</p>
                  </div>
              </div>
              )
          ))
        }
        <div>
          <h4 className='mb-3'>Dados Gerais</h4>
          <label >
              <p>Nome do evento:</p>
              <input 
                  className={error.title ? 'error-input' : ''}
                  type="text" 
                  placeholder="Digite o nome do evento" 
                  disabled={disabled}
                  value={eventInfo.title}
                  onChange={(event) =>
                    setEventInfo((prevData) => ({
                      ...prevData,
                      title: event.target.value,
                    }))
                  }
              />
          </label>
        </div>
        <div className='mt-3'>
            <label>
                <p>Código do evento no Conferências UnB:</p>
                <input 
                    type="text" 
                    placeholder="Digite o código" 
                    disabled={disabled}
                    value={
                      eventInfo.tokenConferencia
                    }
                    onChange={(event) =>
                      setEventInfo((prevData) => ({
                        ...prevData,
                        tokenConferencia: event.target.value,
                      }))
                    }
                />
            </label>
        </div>
        <div className='mt-3'>
            <label>
                <p>Edição nº:</p>
                <input 
                    type="text" 
                    placeholder="Digite o nº da edição" 
                    disabled={disabled}
                    value={eventInfo.edition}
                    onChange={(event) =>
                      setEventInfo((prevData) => ({
                        ...prevData,
                        edition: event.target.value,
                      }))
                    }
                />
            </label>
        </div>
        <div className='mt-3 col-2'>
            <label className='w-100 mr-2'>
                <p>Início</p>
                <InputMask
                    mask="99/99/9999"
                    placeholder='DD/MM/AAAA'
                    value={eventInfo.start}
                    onChange={(event) =>
                      setEventInfo((prevData) => ({
                        ...prevData,
                        start: event.target.value,
                      }))
                    }
                />  
            </label> 
            <label className='w-100'>
                <p>Fim</p>
                <InputMask
                    mask="99/99/9999"
                    placeholder='DD/MM/AAAA'
                    value={eventInfo.end}
                    onChange={(event) =>
                      setEventInfo((prevData) => ({
                        ...prevData,
                        end: event.target.value,
                      }))
                    }
                />  
            </label> 
        </div>
        <div className='mt-3 col-2'>
            <label className='w-100 mr-2'>
                <p>Quantidade de Tótens</p>
                <input
                    type = 'number'
                    placeholder=''
                    value={eventInfo?.settings?.totens}
                    onChange={(event) =>
                      setEventInfo((prevData) => ({
                        ...prevData,
                        settings: {
                          ...prevData.settings,
                          totens: parseInt(event.target.value),
                        },
                      }))
                    }
                />  
            </label> 
            <label className='w-100 mr-2'>
                <p>Quantidade de Balcões</p>
                <input
                    type = 'number'
                    placeholder=''
                    value={eventInfo?.settings?.balcoes}
                    onChange={(event) =>
                      setEventInfo((prevData) => ({
                        ...prevData,
                        settings: {
                          ...prevData.settings,
                          balcoes: parseInt(event.target.value),
                        },
                      }))
                    }
                />  
            </label> 
        </div>
        <div className='mt-3 col-2'>
            <label className='mr-3 w-100'>
              <p className='mb-1'>Permitir envio de Resumo</p>
              <select
                value={eventInfo.settings?.controllers?.permitirEnvio}
                onChange={(event) => {
                  setEventInfo((prevData) => ({
                    ...prevData,
                    settings: {
                      ...prevData.settings,
                      controllers: {
                        ...prevData.settings.controllers,
                        permitirEnvio: event.target.value === 'false'?false:true,
                        permitirAvaliacao : event.target.value === 'true'?false:eventInfo.settings?.controllers?.permitirAvaliacao,
                        permitirCheckin : event.target.value === 'true'?false:eventInfo.settings?.controllers?.permitirCheckin,
                      }
                    }
                  }));
                }}
              >
                
                <option value={eventInfo.settings?.controllers?.permitirEnvio?true:false}>
                  {eventInfo.settings?.controllers?.permitirEnvio?'Sim':'Não'}
                </option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </label>
            <label className='w-100 mr-3'>
              <p className='mb-1'>Permitir avaliação</p>
              <select
                value={eventInfo.settings?.controllers?.permitirAvaliacao}
                onChange={(event) => {
                  setEventInfo((prevData) => ({
                    ...prevData,
                    settings: {
                      ...prevData.settings,
                      controllers: {
                        ...prevData.settings.controllers,
                        permitirEnvio:event.target.value === 'true'?false:eventInfo.settings?.controllers?.permitirEnvio,
                        permitirAvaliacao: event.target.value === 'false'?false:true 
                      }
                    }
                  }));
                }}
              >
                
                <option value={eventInfo.settings?.controllers?.permitirAvaliacao?true:false}>
                  {eventInfo.settings?.controllers?.permitirAvaliacao?'Sim':'Não'}
                </option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </label>
            <label className='w-100'>
              <p className='mb-1'>Permitir checkin</p>
              <select
                value={eventInfo.settings?.controllers?.permitirCheckin}
                onChange={(event) => {
                  setEventInfo((prevData) => ({
                    ...prevData,
                    settings: {
                      ...prevData.settings,
                      controllers: {
                        ...prevData.settings.controllers,
                        permitirCheckin: event.target.value === 'false'?false:true ,
                        permitirEnvio:event.target.value === 'true'?false:eventInfo.settings?.controllers?.permitirEnvio,

                      }
                    }
                  }));
                }}
              >
                
                <option value={eventInfo.settings?.controllers?.permitirCheckin?true:false}>
                  {eventInfo.settings?.controllers?.permitirCheckin?'Sim':'Não'}
                </option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </label>
        </div>
        <h4 className='mb-3 mt-5'>Incluir Turnos</h4>
        <div className="input-turnos ">
            <div className="mt-3 w-100">
              
              <label className='w-100'>
                <p className='mb-1'>Grande Área</p>
                <select
                  value={grandeArea}
                  onChange={(event) => {
                    setGrandeArea(event.target.value);
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="Artes e Humanidades">Artes e Humanidades</option>
                  <option value="Saúde e Vida">Saúde e Vida</option>
                  <option value="Exatas e Tecnológicas">Exatas e Tecnológicas</option>
                </select>
              </label>
            </div>
            <div className="mt-3 w-100">
              <label className='w-100'>
                <p className='mb-1'>Turno</p>
                <select
                  value={turno}
                  onChange={(event) => {
                    setTurno(event.target.value);
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="matutino">Matutino</option>
                  <option value="vespertino">Vespertino</option>
                </select>
              </label>
            </div>
            <div className="mt-3 w-100">
              <label className='w-100 mr-2'>
                  <p>Dia</p>
                  <InputMask
                      mask="99/99/9999"
                      placeholder='DD/MM/AAAA'
                      value={dia}
                      onChange={(event) => {
                        setDia(event.target.value);
                      }}
                  />  
              </label> 
            </div>
            {turno && grandeArea  &&(
              <div className="mt-3 display-flex flex-start">
                  <a
                    className="btn-add btn"
                    onClick={incluirTurno}
                  ><p>+</p>
                  </a>
              </div>
            )
            }
              
          </div>
          
          <div className="boxes box-turnos ">
          <h4 className='mt-5'>Lista de Turnos</h4>
          {eventInfo.settings?.formularios.formulario_artigo[formularioIndex].options.map((e, index) => (
            <div className="box flex-center-space-between" key={index}>
              <div className="pl-2 pt-2 pb-2">
                <p>{e.linkID}</p>
                <span>{e.title}</span><br></br>
                <span>{e.dia?e.dia:'sem data!'}</span>
              </div>
              <a className="btn-icon" onClick={() => handleDeleteTurno(index)}>
                <img src={iconTrash} alt="" />
              </a>
            </div>
          ))}
          </div>   


          <h4 className='mb-3 mt-3'>Incluir Proprietários</h4>
          <div className="input-people">
            <div className="mt-3 w-100">
              <label>
                <p>Digite o cpf para incluir</p>
                <input
                  className={error.title ? 'error-input' : ''}
                  type="text"
                  placeholder="Digite o CPF do proprietário"
                  value={cpf}
                  onChange={(e) => handleCpfValidation(e.target.value)}
                />

                {!cpfValid && (
                  <div className="message mb-2">
                    <div className="error-message border">
                      <p className="font-size-12">
                        Digite um CPF válido
                      </p>
                    </div>
                  </div>
                )}
              </label>
            </div>
            {cpfValidator(cpf) && (
              <div className="mt-3 display-flex flex-end">
                
                {cpfValidator(cpf) && (
                  <a
                    className="btn-add btn"
                    onClick={handleOwner}
                  >
                    <p>+</p>
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="message mb-2 mt-2">
              <div className="error-message border">
                <p className="font-size-12">
                  Clique no botão para incluir proprietário!
                </p>
              </div>
            </div>
          <div className="boxes">
            {eventInfo.owners &&
              eventInfo.owners
                .slice() // Cria uma cópia do array para não afetar o original
                .map((e, index) => (
                  <div
                    className="box flex-center-space-between "
                    key={index}
                  >
                    <div className="pl-2 pt-2 pb-2">
                      <p>{owners[index]}</p>
                      <span>{e}</span>
                    </div>
                    
                      <a className="btn-icon" onClick={() => handleDelete(index)}>
                        <img src={iconTrash} alt="" />
                      </a>
                    

                    
                  </div>
                ))}
          </div>
                     
        </form>
        <button form="myForm" disabled={disabled} className="btn btn-primary mr-2 mt-3 mb-3"><p>Salvar alterações</p></button>
      </section>
    </div>
  </div>
  
</>
);
};

export default Configuracoes;
