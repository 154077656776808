import { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';
import iconClose from 'svg/icon_close.svg';
import { closeModal, openModal } from 'helpers/Modal';
import Modal_newPeople from 'pages/Admin/Modal_newPeople';
import { isEmptyArray } from 'formik';



const Modal_article = ({ 
  formInfo, 
  formData,
  setFormData,
  setFormInfo,
  setRefresh,
  refresh,
  setSearchValue,
  getEventInfo,
  eventosPermitindoSubmissao,
  admin = false,
  cpfUser = '',
  data = null,
  eventInfo = null
}
) => {


const api = useAPI();
const [disabled, setDisabled] = useState(false);
const [error, setError] = useState({});
const [cpf, setCpf] = useState('');
const [cpfAluno, setCpfAluno] = useState('');
const [peopleType, setPeopleType] = useState('');
const [cpfValid, setCpfValid] = useState(false);
const [cpfAlunoValid, setCpfAlunoValid] = useState(false);

const [charCounts, setCharCounts] = useState({});



useEffect(() => {
  const charCountsData = {};
  
  formInfo.forEach((e) => {
    const charCounterId = `char-counter-${e.labelId}`;
    const textValue = formData.content?formData.content[e.labelId] : '';
    const charCount = textValue?textValue.length:0;
    charCountsData[charCounterId] = charCount;
  });
  setCharCounts(charCountsData);
}, [formInfo, formData]);
const handleCpfValidation = (value) => {
  setCpf(value);
  setCpfValid(cpfValidator(value));
};
const handleCpfAlunoValidation = (value) => {
  setCpfAluno(value);
  setCpfAlunoValid(cpfValidator(value));
};

const updateAuthors = (name, type, cpf) => {
  setFormData((prevData) => ({
    ...prevData,
    authors: prevData.authors
      ? [...prevData.authors, { name, type, cpf }]
      : [{ name, type, cpf }],
  }));
};



const handlePeople = async (e) => {
  if (peopleType === "orientador") {
    setError({});
    const json = await api.getPeople(cpf);
    if (json.error) {
      openModal(e, 'newPeople');
      setCpf(cpf);
    } else {
      updateAuthors(json.name, peopleType, json.cpf);
      setCpf('');
      setPeopleType('');
    }
  }
  if (peopleType === "aluno") {
    setError({});
    const json = await api.getPeople(cpfAluno);
    if (json.error) {
      openModal(e, 'newPeople');
      setCpfAluno(cpfAluno);
    } else {
      updateAuthors(json.name, peopleType, json.cpf);
      setCpfAluno('');
      setPeopleType('');
    }
  }

};

const handleDelete = (index) => {
  setFormData((prevData) => {
    const updatedAuthors = [...prevData.authors];
    updatedAuthors.splice(index, 1);
    return {
      ...prevData,
      authors: updatedAuthors,
    };
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setRefresh("")
  setDisabled(true);
  setError({});
  setDisabled(false);
  let resposta = null;
  
  if(formData._id){
    resposta = await api.updateArticle(formData._id,formData.content,formData.authors);
  }else{
    resposta = await api.newArticle(formData.idEvent,formData.content,formData.authors);
    
  }
  await setRefresh("finalizado")
  if (refresh = "finalizado") {
    closeModal(e,"article")
    setFormInfo([]);
    setFormData({});
    //await setSearchValue(formData.content.titulo)
  }


  
};

  return (
    <>
      <div className="container-modal article">
        <div className="modal">
          <div className="content">
            <div className='header-modal'>
              <h3>Formulário de Submissão  </h3>
              <a className="icon-close"  onClick={(e) => {
                  closeModal(e, 'article');
                  setFormData({});
                  setFormInfo([]);
                }}>
                <img src={iconClose} alt="" />
              </a>
            </div>
            
            {formData.idEvent  &&(
              <>
              <div className="content-form">
              <form id="article" onSubmit={handleSubmit} className="mt-4" autoComplete="off">
              {!admin && formData.content?.instituicao === "UNB" ? (<>
                            <div className="message mb-2">
                            <div className="error-message border">
                              <p className="font-size-12">Caso precise editar o título, edital ou autores, entre em contato com o ProIC</p>
                            </div>
                      </div></>
                          ) : null}
                
                {Object.keys(error).map((key, index) =>
                  error[key].msg ? (
                    <div className="message mb-2" key={index}>
                      <div className="error-message border">
                        <p className="font-size-12">{error[key].msg}</p>
                      </div>
                    </div>
                  ) : null
                )}
                {formInfo.map((e, index) => {
                  const charCounterId = `char-counter-${e.labelId}`;
                  const textValue = formData[e.labelId] || '';
                  const charCount = textValue.length;
                  const filteredNotas = formInfo?.filter(item => item.type === 'option-nota');
                  let totalNotas = 0;

                  filteredNotas.forEach(item => {
                    const labelId = item.labelId;
                    totalNotas += eval(formData.content?formData.content[labelId]:0) || 0;
                  });
                  return (
                    <div className="mt-3" key={index}>
                      <label>
                        {e.type === 'text'&& e.view ? (
                          <>
                          <p>{e.labelTitle} <span className="char-counter">({charCounts[charCounterId] || 0})</span>
                            </p>
                            <input
                              className={error.title ? 'error-input' : ''}
                              maxLength={e.max}
                              required={e.required}
                              disabled={e.readonly}
                              type="text"
                              placeholder={e.placeholder}
                              value={formData.content?formData.content[e.labelId] : ''}
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  content: {
                                    ...prevData.content,
                                    [e.labelId]: event.target.value,
                                  },
                                }))
                              }
                            />
                            
                          </>
                        ) : ("")}
                        {e.type === 'number'&& e.view ? (
                          <>
                          <p>{e.labelTitle} <span className="char-counter">({charCounts[charCounterId] || 0})</span>
                            </p>
                            <input
                              className={error.title ? 'error-input' : ''}
                              maxLength={e.max}
                              required={e.required}
                              disabled={e.readonly}
                              type="number"
                              placeholder={e.placeholder}
                              value={formData.content?formData.content[e.labelId] : ''}
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  content: {
                                    ...prevData.content,
                                    [e.labelId]: parseInt(event.target.value),
                                  },
                                }))
                              }
                            />
                            
                          </>
                        ) : ("")}
                        {e.type === 'url'&& e.view ? (
                          <>
                          <p>{e.labelTitle}</p>
                            <input
                              className={error.title ? 'error-input' : ''}
                              required={e.required}
                              disabled={e.readonly}
                              maxLength={e.max}
                              type="url"
                              placeholder={e.placeholder}
                              value={formData.content?formData.content[e.labelId] : ''}
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  content: {
                                    ...prevData.content,
                                    [e.labelId]: event.target.value,
                                  },
                                }))
                              }
                            />
                            
                          </>
                        ) : ("")}
                        {e.type === 'longText'&& e.view ? (
                          <>
                          <p>{e.labelTitle} <span className="char-counter">({charCounts[charCounterId] || 0})</span>
                        </p>
                            <textarea
                            className={error.title ? 'error-input' : ''}
                            maxLength={e.max}
                            required={e.required}
                            disabled={e.readonly}
                            type="text"
                            placeholder={e.placeholder}
                            value={formData.content?formData.content[e.labelId] : ''}
                            onChange={(event) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                content: {
                                  ...prevData.content,
                                  [e.labelId]: event.target.value,
                                },
                              }))
                            }
                          />
                            
                          </>
                        ) : ("")}
                        {e.type === 'option' && e.view ? (
                          <>
                            <p>{e.labelTitle}</p>
                            <select
                              id={e.labelId}
                              disabled={e.readonly}
                              required={e.required}
                              
                              value={formData.content ? formData.content[e.labelId]?.title : ''}
                              onChange={(event) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  content: {
                                    ...prevData.content,
                                    [e.labelId]: event.target.value,
                                  },
                                }));
                                const filtered = formInfo?.filter(item => item.link === e.labelId);
                                
                                if (!isEmptyArray(filtered)) {
                                  filtered.map((item) => {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      content: {
                                        ...prevData.content,
                                        [item.labelId]: "",
                                      },
                                    }));
                                    
                                    return null; // Certifique-se de retornar algo no .map()
                                  });
                                }
                              }}
                            >
                              <option value={formData.content ? formData.content[e.labelId] :''}>
                                {formData.content ? formData.content[e.labelId] : 'Selecione uma opção'}
                              </option>
                              {e.options.map((element, index) => (
                                <option key={index} value={element.title}>
                                  {element.title}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : null}
                        {e.type === 'option-link'&& e.view ? (
                          <>
                          
                          <p>{e.labelTitle}</p>
                          <select id={e.labelId} 
                            required={e.required}
                            disabled={e.readonly}
                            value={formData.content?formData.content[e.labelId] : ''}
                            onChange={(event) =>{
                              setFormData((prevData) => ({
                                ...prevData,
                                content: {
                                  ...prevData.content,
                                  [e.labelId]: event.target.value,
                                },
                              }))
                            }
                            }
                          >
                          <option value={formData.content?formData.content[e.labelId] : ''}>
                            {formData.content?formData.content[e.labelId] : 'Selecione uma opção'}
                          </option>
                          {e.options.map((element, index) => {
                            if (formData.content && formData.content[e.link] === element.linkID) {
                              return (
                                <option key={index} value={element.title}>
                                  {element.title}
                                </option>
                              );
                            }
                            return null; // Return null for cases where the condition is not met
                          })}
                        </select>
        
                            
                          </>
                        ) : ("")}
                        {e.type === 'option-nota' && e.view ? (
                          <>
                            <p>{e.labelTitle}</p>
                            <div className='slider'>
                            <input type="range" id="slider" name="slider" min="0" max="20" step="1"
                            required={e.required}
                            disabled={e.readonly}
                            value={formData.content?formData.content[e.labelId]?formData.content[e.labelId] : "0": "0"}
                            onChange={(event) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                content: {
                                  ...prevData.content,
                                  [e.labelId]: event.target.value,
                                  premiacao:""
                                },
                              }))
                            }
                            
                            />
                            <span id="sliderValue">{formData.content?formData.content[e.labelId]?formData.content[e.labelId] : "0": "0"}</span>
                            </div>
                          </>
                        ) : null}
                        
                        {e.type === 'option-nota-total' && e.view && (totalNotas) >= 90 ? (
                          <>
                            <p>{e.labelTitle}</p>
                            <select
                              id={e.labelId}
                              required={e.required}
                              disabled={e.readonly}
                              value={formData.content ? formData.content[e.labelId]?.title : ''}
                              onChange={(event) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  content: {
                                    ...prevData.content,
                                    [e.labelId]: event.target.value,
                                  },
                                })); 
                                 
                              }}
                            >
                              <option value={formData.content?formData.content[e.labelId] : ''}>
                                {formData.content?formData.content[e.labelId] : 'Selecione uma opção'}
                              </option>
                              
                              {e.options && e.options.length > 0 ? (
                                e.options.map((element, index) => {
                                  // Check if the option should be displayed based on totalNotas
                                  if (
                                    (totalNotas >= 90 && totalNotas < 100 && element.title !== "Indicação a Prêmio Destaque" && element.title !== "Prêmio Destaque") ||
                                    (totalNotas === 100 && element.title !== "Prêmio Destaque")
                                  ) {
                                    return (
                                      
                                      <option key={index} value={element.title}>
                                        {element.title}
                                      </option>
                                    );
                                  }
                                  return null; // Skip this option
                                })
                              ) : null}
                              {admin && totalNotas === 100 &&(
                                <option value="Prêmio Destaque">
                                Prêmio Destaque
                              </option>
                              )}
                            </select>
                          </>
                        ) : null}


                      </label>
                    </div>
                  );
                })}
                
                {formData.idEvent &&(
                  <>
                  {admin || formData?.content?.instituicao !== "UNB" ? (
                    <>
                    <div className="message mb-2">
                      <div className="error-message border">
                      <p className="font-size-14">Leia as instruções abaixo:</p>
                        <p className="font-size-12">
                          Para incluir orientador e aluno faça o seguinte:<br></br>
                          1 - Digite o cpf no campo corresponte a aluno ou orientador;<br></br>
                          2 - Aperte no botão;<br></br>
                          3 - Se o cpf não estiver registrado em nossa base de dados, será aberta uma janela para incluir o nome e o email. Preencha os campos e clique em Cadastrar;<br></br>
                          4 - Repita os passos para incluir outros orientadores e/ou alunos, se for o caso;<br></br>
                          5 - Verifique se todos os alunos e orientadores referentes a esse resumo foram incluídos.
                          </p>
                          <p className="font-size-14 mt-2">Atenção!</p>
                          <p className="font-size-12">Só é possível enviar o resumo caso você seja aluno ou orientador desse resumo.</p>
                      </div>
                    </div>
                    <div className="mt-3 w-100 mb-3">
                    <label>
                      <p>Digite o CPF do orientador</p>
                      <input
                        className={error.title ? 'error-input' : ''}
                        type="text"
                        placeholder="Digite o CPF do orientador"
                        value={cpf}
                        onChange={(e) => {
                          setPeopleType("orientador")
                          handleCpfValidation(e.target.value)
                        }}
                      />

                      {cpf.length>0&&!cpfValid && (
                        <div className="message mb-2">
                          <div className="error-message border">
                            <p className="font-size-12">
                              Digite um CPF válido
                            </p>
                          </div>
                        </div>
                      )}
                    </label>
                    {cpfValidator(cpf) && (
                        <a
                          className="btn btn-primary w-100 text-align-center mt-2"
                          onClick={handlePeople}
                        >
                          <p>Clique para incluir orientador</p>
                        </a>
                      )}
                  </div>
                  <div className="mt-3 w-100 mb-3">
                  <label>
                    <p>Digite o CPF do aluno</p>
                    <input
                      className={error.title ? 'error-input' : ''}
                      type="text"
                      placeholder="Digite o CPF do aluno"
                      value={cpfAluno}
                      onChange={(e) => {
                        setPeopleType("aluno")
                        handleCpfAlunoValidation(e.target.value)
                      }}
                    />

                    {cpfAluno.length>0&&!cpfAlunoValid && (
                      <div className="message mb-2">
                        <div className="error-message border">
                          <p className="font-size-12">
                            Digite um CPF válido
                          </p>
                        </div>
                      </div>
                    )}
                  </label>
                  {cpfValidator(cpfAluno) && (
                      <a
                        className="btn btn-primary w-100 text-align-center mt-2"
                        onClick={handlePeople}
                      >
                        <p>Clique para incluir aluno</p>
                      </a>
                    )}
                </div>
                </>
                  ) : null}
                  
                {/***peopleType !== '' && (
                  <div className="message mb-2 mt-2">
                    <div className="error-message border">
                      <p className="font-size-12">
                        Clique no botão para incluir o autor/orientador!
                      </p>
                    </div>
                  </div>
                )**/}
                {formData?.authors[0] &&(
                  <span className=''>LISTA DE ORIENTADOR(ES) E ALUNO(S)</span>
                )}
                
                <div className="boxes">
                  {formData.authors &&
                    formData.authors
                      .slice() // Cria uma cópia do array para não afetar o original
                      .map((e, index) => (
                        <>
                        
                        <div
                          className="box flex-center-space-between "
                          key={index}
                        >
                          <div className="pl-2 pt-2 pb-2">
                            <p>{e.name}</p>
                            <span>{e.type}</span>
                          </div>
                          {admin || formData.content.instituicao !== "UNB" ? (
                            <a className="btn-icon" onClick={() => handleDelete(index)}>
                              <img src={iconTrash} alt="" />
                            </a>
                          ) : null}

                          
                        </div>
                        </>
                      ))}
                </div>
                  </>
                )
                }
                {admin&&
                <label>
                <p>Relatório final entregue?</p>
                <select
                  value={formData?.content["relatorioFinal"]}
                  onChange={(event) => {
                    const newValue = event.target.value === 'true'; // Convertendo para booleano
                    setFormData((prevData) => ({
                      ...prevData,
                      content: {
                        ...prevData.content,
                        ["relatorioFinal"]: newValue,
                      },
                    }));
                  }}
                >
                  <option value={formData?.content["relatorioFinal"]?true:false}>{formData?.content["relatorioFinal"]?"SIM":"NÃO"}</option>
                  <option value={true}>SIM</option>
                  <option value={false}>NÃO</option>
                </select>
              </label>
              
                }
              </form>
              
            </div>
            {!admin && formData?.authors[0]&&
            //formData.authors?formData.authors[0]:true && 
            !formData?.authors?.find(author => author.cpf === cpfUser) && formInfo[0]&&(
            <div className="message mb-2">
              <div className="error-message border">
                <p className="font-size-12"> Não é possível enviar resumo para terceiros. Você deve ser orientador ou aluno.</p>
              </div>
            </div>
            )
            }
            <div className="modal-actions mt-4">
              {formData?.content?.titulo && 
              formData?.authors[0] && 
              (admin || formData?.authors?.find(author => author.cpf === cpfUser)) &&(
                <button
                  form="article"
                  disabled={disabled}
                  className="btn btn-primary mr-2"
                >
                  <p>Enviar</p>
                </button>
              )

              }
                
   

              <a
                onClick={(e) => {
                  closeModal(e, 'article');
                  setFormData({});
                  setFormInfo([]);
                }}
                className="btn btn-secondary"
                href=""
              >
                <p>Fechar</p>
              </a>
            </div>
              </>
            )}
            
            {!formData.idEvent  &&(
                  <>
                  <label>
                  <p>Selecione um Congresso para enviar o resumo</p>
                      <select
                        value={formData ? formData.idEvent : ''}
                        onChange={(event) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            idEvent: event.target.value,
                          }));   
                          getEventInfo(event.target.value) 
                        }}
                      
                        
                      >
                        <option value=''>
                          Selecione uma opção
                          
                        </option>
                        {eventosPermitindoSubmissao?.map((element, index) => (
                          <option key={index} value={element._id}>
                            {element.title}
                          </option>
                        ))}
                      </select>
                  </label>
                  </>
            )}
            {/* Formulário de Envio de Vídeo */}
      
          </div>
        
        </div>
      </div>
    
      
      <Modal_newPeople
        cpf={cpf}
        cpfAluno={cpfAluno}
        peopleType={peopleType}
        updateAuthors={updateAuthors}
        setCpf={setCpf}
        setCpfAluno={setCpfAluno}
        setPeopleType={setPeopleType}
      />
    </>
  );
};

export default Modal_article;
