import { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';
import iconClose from 'svg/icon_close.svg';
import { closeModal, openModal } from 'helpers/Modal';
import { isEmptyArray } from 'formik';

const Modal_viewArticle = ({ 
  article,cpf,setRefresh
}
) => {
const api = useAPI();
const [disabled, setDisabled] = useState(false);
const [error, setError] = useState({});
//const [cpf, setCpf] = useState('');
const [confirmacao, setConfirmacao] = useState(false);

const [tipoCertificado, setTipoCertificado] = useState('');




const editalOptions = ['PIBIC EM', 'PIBIC/EM', 'PIBITI/JR', 'PIBITI Junior'];

const options = article?.dataEvent?.settings?.formularios?.formulario_artigo;

const desiredElement = options?.find(element => element.labelId === "turnoApresentação");
const turno = article?.content?.turnoApresentação
const grandeArea = article?.content?.grandeArea
const diaApresentacao = desiredElement?.options?.find(element => element.title === turno && element.linkID === grandeArea);



const handleSubmit = async (e) => {
  e.preventDefault();
  article.content.status = 'Aguardando avaliação'
  console.log(article)
  await api.updateArticle(article._id,article.content,article.authors);
  setRefresh(true)
  setConfirmacao(false)
  closeModal(e, 'viewArticle');
}

  return (
    <>
        
        
      <div className="container-modal viewArticle">
      
        <div className="modal">
          <div className="content">
            <div className='header-modal'>
              <h3></h3>
              <a className="icon-close"  onClick={(e) => {
                  closeModal(e, 'viewArticle');
                  setConfirmacao(false)
                }}>
                <img src={iconClose} alt="" />
              </a>
            </div>
            <span>{article?.dataEvent?.title}</span>
            <div className="article ">
              <h3>{article?.content?.titulo}</h3>
              {article?.authors.map((e) => (
                <>
                <span key={e.cpf}>{e.name} ({e.type})</span> <br></br>
                </>
              ))}
              <p className='mt-3'>
              
              </p>
              {article?.content?.status !== 'Avaliado'&&(
                <div className='apresentacao'>
                <div className='content'>
                  
                  <p>
                  {article?.content?.edital} - {article?.content?.grandeArea} - {article?.content?.subArea}<br></br>
                  {article?.content?.poster && editalOptions.includes(article.content.edital) ? (
                    <>BALCÃO Nº:</>) : (<>PÔSTER Nº:</>)} <span>{article?.content?.poster?article.content?.poster:'Não definido'}</span><br></br>
                  DIA DA APRESENTAÇÃO: <span>{diaApresentacao?.dia || 'Não definido'}</span><br />
                  TURNO: <span>{article?.content?.turnoApresentação}</span><br></br>
                  STATUS: <span>{article?.content?.status}</span>
                  </p>
                </div>
                {
                  article?.dataEvent?.settings?.controllers?.permitirCheckin && 
                  article?.content?.poster > 100 &&
                  article?.content?.status === 'Aguardando apresentação'
                  &&(
                    <>
                    <div className="message" >
                      <div className="error-message border display-flex">
                      
                      <input className='w-auto mr-1' type="checkbox" id="checkbox" name="checkbox" checked={confirmacao} onChange={(e) => setConfirmacao(e.target.checked)}></input>
                      <p className="font-size-12">Confirmo que estou no Congresso e meu pôster pode ser avaliado presencialmente.</p>
                          
                      </div>
                    </div>
                    {confirmacao&&(
                      <a onClick={(e) => {handleSubmit(e)}}  className=" btn btn-checkin" href="">
                        <p>Registrar presença</p>
                      </a>
                    )}
                    </>
                  )
                }
                
                
                </div>
              )}
              {article?.content?.status === 'Avaliado'&&(
                <div className='certificados'>
                  <div className='certificado'>
                  <a 
                  href={`/certificado/apresentacao/${article._id}/${cpf}`}
                  target="_blank"
                  rel="noopener noreferrer"

                  className="btn btn-secondary w-100 text-align-center" >
                    Certificado de Apresentação
                  </a>
                  </div>
                </div>
              )}
              {article?.content?.premiacao === 'Menção Honrosa'&&(
                <div className='certificados mt-2'>
                  <div className='certificado'>
                  <a 
                  href={`/certificado/mencaoHonrosa/${article._id}/${cpf}`}
                  target="_blank"
                  rel="noopener noreferrer"

                  className="btn btn-secondary w-100 text-align-center" >
                    Certificado de Menção Honrosa
                  </a>
                  </div>
                </div>
              )}
              {article?.content?.premiacao === 'Indicação a Prêmio Destaque'&&(
                <div className='certificados mt-2'>
                  <div className='certificado'>
                  <a 
                  href={`/certificado/indicacao/${article._id}/${cpf}`}
                  target="_blank"
                  rel="noopener noreferrer"

                  className="btn btn-secondary w-100 text-align-center" >
                    Certificado de Indicação ao Prêmio Destaque
                  </a>
                  </div>
                </div>
              )}
              {article?.content?.premiacao === 'Prêmio Destaque'&&(
                <div className='certificados mt-2'>
                  <div className='certificado'>
                  <a 
                  href={`/certificado/premio/${article._id}/${cpf}`}
                  target="_blank"
                  rel="noopener noreferrer"

                  className="btn btn-secondary w-100 text-align-center" >
                    Certificado de Prêmio Destaque
                  </a>
                  </div>
                </div>
              )}
              {article?.content?.relatorioFinal&&(
                <div className='certificados mt-2'>
                  <div className='certificado'>
                  <a 
                  href={`/certificado/conclusao/${article._id}/${cpf}`}
                  target="_blank"
                  rel="noopener noreferrer"

                  className="btn btn-secondary w-100 text-align-center" >
                    Certificado de Conclusão
                  </a>
                  </div>
                </div>
              )}
              
              
              <p className='mt-3'>{article?.content?.introdução}</p>
              <p>{article?.content?.metodologia}</p>
              <p>{article?.content?.resultado}</p>
              <p>{article?.content?.conclusao}</p>
              <p>{article?.content?.palavras_chaves}</p>
            </div>
            
            
            

          </div>
        </div>
        
      </div>
      
    </>
  );
};

export default Modal_viewArticle;
