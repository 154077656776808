import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';

const MenuAdmin = ({menu}) => {
  const api = useAPI();
  const { id } = useParams();
  const [eventInfo, setEventInfo] = useState({});



  return (
    <>
        <div className="menu-admin">
          <ul>
            {menu.map((e) => (
              <li className={e.selected ? 'selected' : ''} key={e.title}>
                <a href={e.link}>{e.title}</a>
              </li>
            ))}
          </ul>
        </div>

    </>
  );
};

export default MenuAdmin;
