import Header from 'pages/Header';
import Modal_newEvent from 'pages/Modal/newEvent';
import Modal_editMyProfile from 'pages/Modal/editMyProfile';

import {closeModal, openModal, disableScroll, enableScroll} from 'helpers/Modal';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { v4 as uuidv4 } from 'uuid';

const Profile = ()=>{
    const api = useAPI();
    const [events,setEvents] = useState([])
    const [searchValue, setSearchValue] = useState('');

    useEffect(()=>{
        const getEvents = async () => {
            const json = await api.getEvents({
                sort:'desc'
            });
            setEvents(json);
        }
        getEvents();
    },[])
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
      };
const desiredLength = 6; // Defina o número desejado de caracteres
      // Filtra a lista de resumos com base no valor do input de busca
      let filteredData =[]
      if(events.length>0){
        filteredData = events.filter((item) => {
          const lowerCaseSearchValue = searchValue.toLowerCase();
          const lowerCaseTitle = item?.title?.toLowerCase() || '';
        
          return (
            lowerCaseTitle.includes(lowerCaseSearchValue)
          );
        });
      }
return(
    <>
<Header/>
<Modal_newEvent/>
<Modal_editMyProfile/>
<div className="body-admin">
<div className="container-admin container-profile">
    <section id="form-submissao">
    <div className="header">
        <h4 >Seus congressos ({filteredData > 0 ?filteredData.length: filteredData.length })</h4>

        <div className='actions'>
            <a onClick={(e) => openModal(e, 'newEvent')} className="btn btn-primary mr-1" href=""><p>Criar Evento</p></a>
            <a onClick={(e) => openModal(e, 'editMyProfile')}  className="btn btn-secondary" href=""><p>Meus dados</p></a>
        </div>
        
    </div>
    <div className="search-input">
        <input
        type="text"
        value={searchValue}
        onChange={handleSearchChange}
        placeholder="Buscar"
        />
    </div>
    
    {filteredData.length > 0 ? (
              <div className="boxes">
                {filteredData.map((e, index) => (
                    <a className="box" href={`/admin/listaRecebimento/${e._id}`}>
                        <div className='box-content'>
                            <p>{e.title}</p>
                        </div>
                    </a>
                ))}
                
              </div>
            ) : (
              <p>Nenhum resumo encontrado.</p>
            )}
    
    </section>
</div>
</div>

    </>
    )
    };
    
    export default Profile;