import { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';
import iconClose from 'svg/icon_close.svg';
import { Link } from 'react-router-dom';

import { closeModal, openModal } from 'helpers/Modal';

const Modal_menu = ({eventInfo,menu}) => {
  const api = useAPI();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({});
  const [cpf, setCpf] = useState('');
  const [peopleType, setPeopleType] = useState('');
  const [cpfValid, setCpfValid] = useState(false);
  const [charCounts, setCharCounts] = useState({});


  return (
    <>
      <div className="container-modal menu">
        <div className="modal">
          <div className="content">
            <div className='header-modal'>
              
              <a className="icon-close"  onClick={(e) => {
                  closeModal(e, 'menu');
                }}>
                <img src={iconClose} alt="" />
              </a>
            </div>
            
            <div className="content-form">
            <ul>
            {menu.map((e) => (
              <li className={e.selected ? 'selected' : ''} key={e.title}>
                <a href={e.link}>{e.title}</a>
              </li>
            ))}
              
            </ul>
            </div>
            <div className="modal-actions mt-4">
    
              
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Modal_menu;
