function menuAdmin(i, idEvent) {
  const menuList = [
    {title: 'Resumos', link: `/admin/listaRecebimento/${idEvent}`, selected: false},
    {title: 'Programação', link: `/admin/programacao/${idEvent}`, selected: false},
    {title: 'Configurações', link: `/admin/configuracoes/${idEvent}`, selected: false},
    {title: 'Avaliadores', link: `/admin/avaliadores/${idEvent}`, selected: false},
    {title: 'Dashboard', link: `/admin/dashboard/${idEvent}`, selected: false},

  ];
  
  const menu = menuList.map((e, index) => {
    if (i === index) {
      return { ...e, selected: true }; // Use spread operator para criar um novo objeto com a propriedade 'selected' atualizada
    } else {
      return e; // Mantém os outros objetos inalterados
    }
  });

  return menu;
}

export default menuAdmin;

