import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { useParams } from 'react-router-dom';
import { openModal } from 'helpers/Modal';
import Modal_upload from 'pages/Admin/Modal_upload';
import Modal_newAvaliador from 'pages/Admin/Modal_newAvaliador';
import Modal_menu from 'pages/Admin/Modal_menu';
import HeaderAdmin from 'pages/Admin/HeaderAdmin';
import MenuAdmin from 'pages/Admin/MenuAdmin';
import { resetFormValues } from "helpers/resetForm";
import menuAdmin from 'helpers/menuAdmin';
import * as XLSX from 'xlsx'; // Importe a biblioteca xlsx-populate
import { saveAs } from 'file-saver';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';

const Avaliadores = () => {
  const api = useAPI();
  const { id } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [events,setEvents] = useState([])
  const [articles,setArticles] = useState([])
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [formInfo, setFormInfo] = useState([]);
  const [formData, setFormData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [eventInfo, setEventInfo] = useState({});
  const [data, setData] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null); // Estado para armazenar o item selecionado
  const [eventosPermitindoSubmissao,seteventosPermitindoSubmissao] =useState([]);
  const [menu,setMenu]=useState([]);
  const [cpfValid, setCpfValid] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);


  const handleCpfValidation = (value) => {
    setCpf(value);
    setCpfValid(cpfValidator(value));
  };
  const updateAvaliador = (name, cpf) => {
    setFormData((prevData) => ({
      ...prevData,
      authors: prevData.authors
        ? [...prevData.authors, { name,  cpf }]
        : [{ name,  cpf }],
    }));
  };
  
  const handleAvaliador = async (e) => {
    setError({});
    const json = await api.getPeople(cpf);
    if (json.error) {
      openModal(e, 'newAvaliador');
      setCpf(cpf);
    } else {
      eventInfo.avaliadores? eventInfo.avaliadores.push(json.cpf):eventInfo.avaliadores=[json.cpf]
      await api.updateEvent(id,eventInfo)
      setCpf('');
    }
  };
  const handleDelete = async (cpf) => {
    const newArray = [...eventInfo.avaliadores];
    const indexCPF = newArray.findIndex((e)=>e === cpf)
    
    
    if (indexCPF >= 0 && indexCPF < newArray.length) {
      // Remova o item do newArray
      newArray.splice(indexCPF, 1);
      
      // Atualize o evento no banco de dados
      eventInfo.avaliadores = newArray
      await api.updateEvent(id, eventInfo );
      setShouldUpdate(true);

    }
  };
  
  
  

useEffect(() => {
    const fetchData = async () => {
      //setRefresh(false);
        try {
          setMenu(menuAdmin(3,id))
          const json = await api.getEventById(id);
          setEventInfo(json);
          console.log(json)
          if (json.avaliadores) {
            const newData = await incluirDadosPessoais(json.avaliadores)
            setData(newData);
            
          }
          console.log(data)
        } catch (error) {
            // Lidar com erros, se necessário
        }
    };
    fetchData();
    setShouldUpdate(false); // Redefina o estado de shouldUpdate após o uso
    //setRefresh(false);
}, [cpf,shouldUpdate,refresh]);
 
//Inclui informação dos autores e do evento em cada item 
const incluirDadosPessoais = async (bd) => {
    try {
      
      const listaDeUsuarios = await api.getListPeople();
      
      const newData = [];
      bd.forEach(cpf => {
        const user = listaDeUsuarios.find(usuario => usuario.cpf === cpf);
    
        if (user) {
          newData.push({
            cpf: user.cpf,
            nome: user.name,
            email: user.email,
          });
        }
      });
        return newData;
    } catch (error) {
        console.error("Erro ao incluir informações dos autores:", error);
        return bd;
    }
};


    


const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
};
// Filtra a lista de resumos com base no valor do input de busca
let filteredData = [];

if (data.length > 0) {
  filteredData = data.filter((item) => {
    const lowerCaseSearchValue = searchValue.toLowerCase();

    // Verificar se o CPF ou email do item atual inclui o valor de busca
    const lowerCaseCpf = item?.cpf?.toLowerCase() || '';
    const lowerCaseEmail = item?.email?.toLowerCase() || '';
    const lowerCaseNome = item?.name?.toLowerCase() || '';


    return lowerCaseCpf.includes(lowerCaseSearchValue)|| lowerCaseNome.includes(lowerCaseSearchValue) || lowerCaseEmail.includes(lowerCaseSearchValue);
  });
}


 
return (
<>

<Modal_newAvaliador
        cpf={cpf}
        updateAuthors={updateAvaliador}
        setCpf={setCpf}
        handleAvaliador = {handleAvaliador}
        setRefresh = {setRefresh}
      />

<Modal_menu eventInfo={eventInfo} menu={menu} />
<Modal_upload />
<HeaderAdmin />
  <div className="body-admin">
    <MenuAdmin menu = {menu}/>
    <div className="container-admin container-admin-menu">
      <h3 className='mb-3'>Avaliadores</h3>
      <section id="form-submissao">
        <div className="header">
          <h4>Lista de Avaliadores ({filteredData > 0 ?filteredData.length: filteredData.length })</h4>
          <div className='actions'>
            
              <div className="input-people">
              <div className="mt-3 w-100 ">
                <div className='incluir-avaliador'>
                    <label>
                      <p>Incluir Avaliador</p>
                      
                      <input
                        className={error.title ? 'error-input' : ''}
                        type="text"
                        placeholder="Digite o CPF do avaliador"
                        value={cpf}
                        onChange={(e) => handleCpfValidation(e.target.value)}
                      />

                      {!cpfValid && cpf && (
                        <div className="message mb-2">
                          <div className="error-message border">
                            <p className="font-size-12">
                              Digite um CPF válido
                            </p>
                          </div>
                        </div>
                      )}
                    </label>
                    {cpfValidator(cpf)  && (
                        <a
                          className="btn-add btn"
                          onClick={handleAvaliador}
                        >
                          <p>+</p>
                        </a>
                      )}
                  </div>    
                  </div>
              </div>
          </div>
        </div>
        <div className="search-input">
          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Buscar"
          />
        </div>
        {filteredData.length > 0 ? (
            <div className="boxes">
                {filteredData.map((e, index) => (
                <div className="box flex-center-space-between " key={index}>
                  <div className="pl-2 pt-2 pb-2">
                    <p>{e.nome}</p>
                    <span>{e.email}</span>
                  </div>
                  <a className="btn-icon" onClick={() => handleDelete(e.cpf)}>
                      <img src={iconTrash} alt="" />
                  </a>

                  
                </div>
                ))}
            </div>
            ) : (
            <p>Nenhum resumo encontrado.</p>
        )}
      </section>
    </div>
  </div>
</>
);
};

export default Avaliadores;
