import {closeModal, openModal} from 'helpers/Modal';
import { useEffect, useState } from 'react';
import useAPI from 'helpers/API';
import { upload } from '@testing-library/user-event/dist/upload';
const Modal_newAvaliador = ({cpf, updateAvaliador = '',setCpf,handleAvaliador, setRefresh})=>{
    const api = useAPI();
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    
    const handleSubmitNewAvaliador = async (e)=>{
        e.preventDefault();
        setError('');
        const json = await api.newPeople(name,email, cpf );
        if (json.error) {
            setError(json.error);
            setDisabled(false);
        }else{
          handleAvaliador(cpf)
          closeModal(e, 'newAvaliador');
          
          setName('');
          setEmail('')
        }
       

    }
    return(
<>
<div className="container-modal newAvaliador">
  <div className="modal">
    <div className="content">
      <h3>Cadastro de Avaliador</h3>
      <div className="content-form">
        <form id="newAvaliador" onSubmit={handleSubmitNewAvaliador} className="mt-4" autoComplete="off">
          {Object.keys(error).map((key, index) => (
            error[key].msg && (
              <div className="message mb-2" key={index}>
                <div className="error-message border">
                  <p className="font-size-12">{error[key].msg}</p>
                </div>
              </div>
            )
          ))}
        

        <div className='mt-3 w-100'>
            <label>
                <p>Nome completo</p>
                <input
                className={error.name ? 'error-input' : ''}
                type="text"
                placeholder='Digite o nome completo'
                value={name}
                onChange={e=>setName(e.target.value)}
                />
            </label>
        </div>
        <div className='mt-3 w-100'>
            <label>
                <p>Email</p>
                <input
                className={error.email ? 'error-input' : ''}
                type="email"
                placeholder='Digite o email'
                value={email}
                onChange={e=>setEmail(e.target.value)}
                />
            </label>
        </div>
        <div className='mt-3 w-100'>
            <label>
                <p>CPF</p>
                <input
                className={error.cpf ? 'error-input' : ''}
                type="text"
                placeholder='Digite o cpf'
                value={cpf}
                readOnly
                />
            </label>
        </div>
        </form>
      </div>
      <div className="modal-actions mt-4">
        <button form="newAvaliador" disabled={disabled} className="btn btn-primary mr-2">
            <p>Enviar</p>
          </button>
          <a onClick={(e) => { 
            closeModal(e, 'newAvaliador'); setError(''); 
            setCpf('');
            setName('');
            setEmail('')
            }} className="btn btn-secondary" href="">
            <p>Fechar</p>
            </a>

      </div>
    </div>
  </div>
</div>
</>
)
};
export default Modal_newAvaliador;