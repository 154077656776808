import {closeModal, openModal} from 'helpers/Modal';
import React, {useState, useEffect} from 'react';
import useAPI from 'helpers/API';
import InputMask from 'react-input-mask';
import { stringify } from 'qs';

const Modal_editMyProfile = ()=>{
    const api = useAPI();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [institutions, setInstitutions] = useState([]);
    const [idInstitution, setIdInstitution] = useState('');

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState({});
    const [minhasAvaliacoes, setMinhasAvaliacoes] = useState([]);
    useEffect( ()=>{
        const getInstitutions = async () => {
            const json = await api.getInstitutions();
            setInstitutions(json);
             
        }
        getInstitutions()
        const minhasAvaliacoes = async (cpf) => {
            try {
                const meusArtigoAvaliados = await api.getArtigosAvaliadosPor(cpf);
                const eventTitles = [];
            
                for (const artigo of meusArtigoAvaliados) {
                    const idEvent = artigo.idEvent;
            
                    // Verifica se o idEvent já foi processado
                    if (!eventTitles.some(event => event.id === idEvent)) {
                        const edicaoAvaliador = await api.getEventById(idEvent);
                        eventTitles.push({id: idEvent, title: edicaoAvaliador.title });
                    }
                }
                setMinhasAvaliacoes(eventTitles)
                console.log(eventTitles);
            } catch (error) {
                console.error("Erro ao buscar artigos avaliados:", error);
            }
        }
        
        //
        
        const getMyProfile = async () => {
            try {
                const json = await api.getMyProfile();
                setName(json.name);
                setEmail(json.email);
                setCpf(json.cpf);
                setIdInstitution(json.idInstitution);
                await minhasAvaliacoes(json.cpf); // Aqui estamos esperando pela resolução da função minhasAvaliacoes
            } catch (error) {
                console.error("Erro ao buscar perfil:", error);
            }
        }
        getMyProfile()
    },[])
    //const desiredInstitution = institutions.find(institution => institution._id === myProfile.idInstitution);
    //const institutionAcronym = desiredInstitution ? desiredInstitution.acronym : "";
    
    const handleSubmit = async (e)=>{
        e.preventDefault();
        setDisabled(true);
        setError('');
        const json = await api.editMyProfile(name, email, cpf,idInstitution,password,newPassword);
        if (json.error) {
            setError(json.error);
            setDisabled(false);
        }else{
            closeModal(e, 'editMyProfile')
            setPassword('');
            setNewPassword('');
        }

        setDisabled(false);
        
    }
    return(
    <>
    <div className="container-modal editMyProfile">
        <div className="modal ">
            <div className="content ">
            {minhasAvaliacoes && (
                    <>
                        
                        {minhasAvaliacoes.map((e) => (
                            <>
                            <h3 >Meus Certificados</h3>
                            <div className='certificados mt-2 mb-2' key={e.id}>
                                <div className='certificado'>
                                    <a
                                        href={`/certificado/avaliador/${e.id}/${cpf}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-secondary w-100 text-align-center"
                                    >
                                        {e.title}
                                    </a>
                                </div>
                            </div>
                            </>
                        ))}
                    </>
                )}

                <h3>Meu Perfil!</h3>
                <div className="content-form">
                    <form id="myFormProfile" onSubmit={handleSubmit} className="mt-4" autoComplete="off">
                    {
                    Object.keys(error).map((key, index) => (
                        error[key].msg && (
                        <div className="message mb-2" key={index}>
                            <div className="error-message border">
                            <p className='font-size-12'>{error[key].msg}</p>
                            </div>
                        </div>
                        )
                    ))
                }
                        <div>
                            <label>
                                <p>Nome:</p>
                                <input 
                                    className={error.name ? 'error-input' : ''}
                                    type="text" 
                                    placeholder="Digite o seu nome" 
                                    disabled={disabled}
                                    value={name}
                                    onChange={e=>setName(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <p>Email:</p>
                                <input 
                                    className={error.email ? 'error-input' : ''}
                                    type="email" 
                                    placeholder="Digite o seu email" 
                                    disabled={disabled}
                                    value={ email}
                                    onChange={e=>setEmail(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <p>CPF:</p>
                                <input 
                                    className={error.cpf ? 'error-input' : ''}
                                    type="text" 
                                    placeholder="Digite o seu CPF" 
                                    disabled={disabled}
                                    value={cpf}
                                    onChange={e=>setCpf(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <p>Instituição:</p>
                                <select
                                className={error.idInstitution ? 'error-input' : ''}
                                value={idInstitution} 
                                onChange={e=>setIdInstitution(e.target.value)}
                                >
                                    <option value={idInstitution ? idInstitution :''}>
                                        {idInstitution ? institutions.find(inst => inst._id === idInstitution)?.acronym  : 'Selecione uma opção'}
                                    </option>
                                    {
                                    institutions.map((i) => (
                                        <option key={i._id} value={i._id}>
                                          {i.acronym}
                                        </option>
                                      ))
                                    }
                                </select>
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <p>Senha:</p>
                                <input 
                                    className={error.password ? 'error-input' : ''}
                                    type="password" 
                                    placeholder="Senha atual" 
                                    disabled={disabled}
                                    value={password}
                                    onChange={e=>setPassword(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <p>Nova senha:</p>
                                <input 
                                    className={error.newPassword ? 'error-input' : ''}
                                    type="password" 
                                    placeholder="Nova senha (opcional)" 
                                    disabled={disabled}
                                    value={newPassword}
                                    onChange={e=>setNewPassword(e.target.value)}
                                />
                            </label>
                        </div>
                        
                    </form>
                </div>
                <div className="modal-actions mt-4">
                <button form="myFormProfile" disabled={disabled} className="btn btn-primary mr-2"><p>Salvar</p></button>
                <a onClick={(e) => closeModal(e, 'editMyProfile')} className="btn btn-secondary btn-add" href=""><p>Fechar</p></a>
            </div>
            </div>
            
        </div>

    </div>
    </>
    )
    };
    
    export default Modal_editMyProfile;

    