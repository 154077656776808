import {closeModal, openModal} from 'helpers/Modal';
import React, {useState, useEffect} from 'react';
import useAPI from 'helpers/API';
import InputMask from 'react-input-mask';

const Modal_newEvent = ()=>{ 
    const api = useAPI();
    const [title, setTitle] = useState('');
    const [edition, setEdition] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');
    
    const handleSubmit = async (e)=>{
        e.preventDefault();
        setDisabled(true);
        setError('');
        
        const json = await api.newEvent(title, edition, start, end);
        
        if (json.error) {
            setError(json.error);
            setDisabled(false);
        }else{
            window.location.href = '/profile'
        }

        setDisabled(false);
    }
    return(
    <>
    <div className="container-modal newEvent">
        <div className="modal ">
            <div className="content ">
                <h3>Crie seu Evento!</h3>
                <div className="content-form">
                    <form id="myForm" onSubmit={handleSubmit} className="mt-4" autocomplete="off">
                    {
                    Object.keys(error).map((key, index) => (
                        error[key].msg && (
                        <div className="message mb-2" key={index}>
                            <div className="error-message border">
                            <p className='font-size-12'>{error[key].msg}</p>
                            </div>
                        </div>
                        )
                    ))
                }
                        <div>
                            
                            <label >
                            
                                <p>Nome do evento:</p>
                                <input 
                                    className={error.title ? 'error-input' : ''}
                                    type="text" 
                                    placeholder="Digite o nome do evento" 
                                    disabled={disabled}
                                    value={title}
                                    onChange={e=>setTitle(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className='mt-3'>
                            <label>
                                <p>Edição nº:</p>
                                <input 
                                    type="text" 
                                    placeholder="Digite o nº da edição" 
                                    disabled={disabled}
                                    value={edition}
                                    onChange={e=>setEdition(e.target.value.replace(/\D/g, ''))}
                                />
                            </label>
                        </div>
                        <div className='mt-3 col-2'>
                            <label className='w-100 mr-2'>
                                <p>Início</p>
                                <InputMask
                                    mask="99/99/9999"
                                    placeholder='DD/MM/AAAA'
                                    value={start}
                                    onChange={e=>setStart(e.target.value)}
                                />  
                            </label> 
                            <label className='w-100'>
                                <p>Fim</p>
                                <InputMask
                                    mask="99/99/9999"
                                    placeholder='DD/MM/AAAA'
                                    value={end}
                                    onChange={e=>setEnd(e.target.value)}
                                />  
                            </label> 
                            
                        </div>
                        
                    </form>
                </div>
                <div className="modal-actions mt-4">
                <button form="myForm" disabled={disabled} className="btn btn-primary mr-2"><p>Criar Evento</p></button>
                <a onClick={(e) => closeModal(e, 'newEvent')} className="btn btn-secondary" href=""><p>Fechar</p></a>
            </div>
            </div>
            
        </div>

    </div>
    </>
    )
    };
    
    export default Modal_newEvent;

    