import { useState, useEffect } from 'react';
import useAPI from 'helpers/API';
import cpfValidator from 'helpers/cpfValidator';
import iconTrash from 'svg/icon_trash.svg';
import iconClose from 'svg/icon_close.svg';
import { closeModal, openModal } from 'helpers/Modal';
import { isEmptyArray } from 'formik';
import logo from 'svg/cic_completa.svg';
import fapdf from 'svg/FAPDF - cor.svg';
import cnpq from 'svg/CNPq - cor.svg';
import ifg from 'svg/IFG-AL-cor.svg';
import ifb from 'svg/IFB - cor.svg';
import unb from 'svg/UnB - cor.svg';
import udf from 'svg/UDF - cor.svg';
import iesb from 'svg/IESB - cor.svg';
import ceub from 'svg/CEUB cor.svg';
import ucb from 'svg/UCB - cor.svg';
import escs from 'svg/ESCS - cor.svg';
import QRCode from 'qrcode.react';

import InlineSVG from 'react-inlinesvg'; // Importe o componente InlineSVG
import { useParams } from 'react-router-dom';

const Modal_certificado = ({ 
}
) => {
const api = useAPI();
const { idArtigoOuIdEvento } = useParams();
const { cpf } = useParams();
const { tipo } = useParams();

const [disabled, setDisabled] = useState(false);
const [error, setError] = useState({});
const [alunos,setAlunos] = useState([]);
const [orientadores,setOrientadores] = useState([]);
const [titulo,setTitulo] = useState('');
const [edicao,setEdicao]= useState('');
const [inicio,setInicio]= useState('');
const [fim,setFim]= useState('');
const [edital,setEdital]= useState('');
const [grandeArea,setGrandeArea]= useState('');
const [ano,setAno]= useState('');

const [data,setData]= useState(null);
const [user,setUser]= useState(null);

const [validate,setValidate]= useState(false);
const [loading,setLoading]= useState(false);

const [link,setLink]=useState('')

const qrCodeStyle = {
  background: '#EDF2F2',
  width: '50px', // Define a largura do QR code
  height: '50px', // Define a altura do QR code
};
useEffect(() => {
  const fetchData = async () => {
    setLoading(false);
    
    try {
      let dataNew = [];

      // Dados do Perfil do usuário
      const profileJson = await api.getPeople(cpf);
      
      // Certificado
      const certificado = async () => {
        const artigos = await api.getListArticleByCPF(cpf);
        const artigoByID = artigos.find((e) => e._id === idArtigoOuIdEvento);
        
        // Artigos do usuário
        dataNew = await incluirInfoAutoresEevento([artigoByID]);
        setData(dataNew);
      };
      const certificadoAvaliador = async () => {
        
        const articles = await api.getListArticle(idArtigoOuIdEvento);
        
          const user = await api.getPeople(cpf)
          let article = articles.find(article => article.content.avaliador === cpf);
          
          setUser(user)
          const edicaoAvaliador = await api.getEventById(article.idEvent);
          article.dataEvent = edicaoAvaliador
          dataNew = [article]


        setData(dataNew);
      };
      
      
      switch (tipo) {
        case "apresentacao": 
          await certificado()
          if (dataNew[0].content.status === 'Avaliado') {
            setValidate(true);
          }
          break;
        case "mencaoHonrosa":  
          await certificado()
          if (dataNew[0].content.premiacao === 'Menção Honrosa') {
            setValidate(true);
          }
          break;
        case "indicacao": 
          await certificado()
          if (dataNew[0].content.premiacao === 'Indicação a Prêmio Destaque') {
            setValidate(true);
          }
          break;
        case "premio": 
          await certificado()
          if (dataNew[0].content.premiacao === 'Prêmio Destaque') {
            setValidate(true);
          }
          break;
        case "avaliador": 
          await certificadoAvaliador()
            //if (dataNew[0]) {
              setValidate(true);
            //}
          
          break;
        case "conclusao": 
        await certificado()
        if (dataNew[0].content.relatorioFinal) {
          setValidate(true);
        }
        
        break;
          
        default:
          setValidate(false);
          break;
      }



      const orientador = dataNew[0]?.authors?.filter(autor => autor.type === 'orientador');
      setOrientadores(orientador);
      const aluno = dataNew[0]?.authors?.filter(autor => autor.type === 'aluno');
      setAlunos(aluno );
      setTitulo(dataNew[0].content.titulo); 
      setEdicao(dataNew[0].dataEvent.title);
      setAno(dataNew[0].dataEvent.edition);
      setInicio(dataNew[0].dataEvent.start);
      setFim(dataNew[0].dataEvent.end);
      setEdital(dataNew[0].content.edital);
      setGrandeArea(dataNew[0].content.grandeArea);
      setLink(`https://www.icdf.com.br/certificado/${tipo}/${idArtigoOuIdEvento}/${cpf}`)
    } catch (error) {
      // Lidar com erros, se necessário
    }
    
    setLoading(true);
  };

  fetchData();
}, []);
const incluirInfoAutoresEevento = async (bd) => {
  try {
      const newData = await Promise.all(
      bd.map(async (item) => {
          const autoresComInfo = await Promise.all(
          item.authors.map(async (autor) => {
              const dataUser = await api.getPeople(autor.cpf);
              if (dataUser) {
              return { ...autor, name: dataUser.name };
              }
              return autor;
          })
          );
          const dataEvent = await api.getEventById(item.idEvent)
          return { ...item, authors: autoresComInfo,dataEvent };
      })
      );
      return newData;
  } catch (error) {
      console.error("Erro ao incluir informações dos autores:", error);
      return bd;
  }
};
  return (
    <>
      <div className="certificado-body">
        <div className='body'>
        <div className='header'>
          <InlineSVG src={logo} alt="" />
          <div className='title'>
            <h4>CONGRESSO DE <br></br>INICIAÇÃO CIENTÍFICA</h4>
            <p>da Universidade de Brasília <br></br> e do Distrito Federal</p>
          </div>
        </div>
        <div className='section'>
          {loading && validate &&(
            <div className='content'>
            <h4>CERTIFICADO</h4>
            {tipo === "apresentacao" &&(
              <p>Certificamos que o trabalho <span>{titulo}</span>, 
              elaborado por <span>{alunos?.map(aluno => aluno?.name).join(' e ')}</span>, sob a orientação de <span>{orientadores?.map(orientador => orientador?.name).join(' e ')}</span>, 
              foi apresentado no <span>{edicao}</span>, no período de <span>{inicio}</span> a <span>{fim}</span>. 
              </p>
            )}
            {tipo === "mencaoHonrosa" &&(
              <p>Certificamos que o trabalho <span>{titulo}</span>, 
              elaborado por <span>{alunos?.map(aluno => aluno?.name).join(' e ')}</span>, sob a orientação de <span>{orientadores?.map(orientador => orientador?.name).join(' e ')}</span>, 
              foi agraciado com <span>Menção Honrosa</span> no <span>{edicao}</span>, realizado no período de <span>{inicio}</span> a <span>{fim}</span>. 
              </p>
            )}
            {tipo === "indicacao" &&(
              <p>Certificamos que o trabalho <span>{titulo}</span>, 
              elaborado por <span>{alunos?.map(aluno => aluno?.name).join(' e ')}</span>, sob a orientação de <span>{orientadores?.map(orientador => orientador?.name).join(' e ')}</span>, 
              foi indicado ao <span>Prêmio Destaque de Iniciação Científica</span> do <span>{edicao}</span>, realizado no período de <span>{inicio}</span> a <span>{fim}</span>. 
              </p>
            )}
            {tipo === "premio" &&(
              <p>Certificamos que o trabalho <span>{titulo}</span>, 
              elaborado por <span>{alunos?.map(aluno => aluno?.name).join(' e ')}</span>, sob a orientação de <span>{orientadores?.map(orientador => orientador?.name).join(' e ')}</span>, 
              foi premiado no <span>{edicao}</span>, realizado no período de <span>{inicio}</span> a <span>{fim}</span>, na categoria <span>{edital}-{grandeArea}</span>. 
              </p>
            )}
            {tipo === "avaliador" &&(
              <p>Certificamos que <span>{user.name}</span> 
              foi avaliador(a) no <span>{edicao}</span>, evento realizado no período de <span>{inicio}</span> a <span>{fim}</span>. 
              </p>
            )}
            {tipo === "conclusao" &&(
              <p>Certificamos que <span>{alunos?.map(aluno => aluno?.name).join(' e ')}</span> {alunos.length>1?"participaram":"participou"}, durante o período de 12 meses, do Programa de Iniciação Científica (Edital {edital} - ProIC/UnB/CNPq/FAPDF {ano-1}/{ano}) e 
               {alunos.length>1?" conluíram ":" concluiu "} o Plano de Trabalho intitulado <span>{titulo}</span>, sob a orientação de <span>{orientadores?.map(orientador => orientador?.name).join(' e ')}</span>.
              </p>
            )}
            
              <div className='autenticacao'>
                <QRCode 
                value={link}
                style={qrCodeStyle}
                />
                <span>Comissão Organizadora <br></br>
                <p>A autenticidade deste certificado pode ser verificada escaneando o QR Code ao lado ou pelo endereço <a href={link}>{link}</a> </p>
                </span>
              </div>
          </div>
          )}
          {loading&& !validate &&(
            <div className='content'>
              <h4>CERTIFICADO INEXISTENTE</h4>
            
                <p className='error'>Não há registro de certificado em nossa base de dados com as informações fornecidas. Qualquer dúvida, entre em contato com a organização do evento por meio do email pibic@unb.br</p>
            
            </div>
          )}
        
        <div className='footer'>
          <div>
            <InlineSVG src={fapdf} alt="" />
            <InlineSVG src={cnpq} alt="" />
          </div>
          <div className='div2'>
            <InlineSVG src={ifg} alt="" />
            <InlineSVG src={ifb} alt="" />
            <InlineSVG src={unb} alt="" />
            <InlineSVG src={udf} alt="" />
            <InlineSVG src={iesb} alt="" />
            <InlineSVG src={ceub} alt="" />
            <InlineSVG src={ucb} alt="" />
            <InlineSVG src={escs} alt="" />
          </div>
          
        </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Modal_certificado;
